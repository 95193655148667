import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _worker_threads2 from "worker_threads";

var _worker_threads = "default" in _worker_threads2 ? _worker_threads2.default : _worker_threads2;

import * as _perf_hooks2 from "perf_hooks";

var _perf_hooks = "default" in _perf_hooks2 ? _perf_hooks2.default : _perf_hooks2;

import * as _os2 from "os";

var _os = "default" in _os2 ? _os2.default : _os2;

import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var process = _process;

var WasmBackendModuleThreadedSimd = function () {
  var _scriptDir = typeof document !== "undefined" && document.currentScript ? document.currentScript.src : undefined;

  if (typeof new URL(import.meta.url).pathname !== "undefined") _scriptDir = _scriptDir || new URL(import.meta.url).pathname;
  return function (WasmBackendModuleThreadedSimd) {
    WasmBackendModuleThreadedSimd = WasmBackendModuleThreadedSimd || {};

    function GROWABLE_HEAP_I8() {
      if (wasmMemory.buffer != buffer) {
        updateGlobalBufferAndViews(wasmMemory.buffer);
      }

      return HEAP8;
    }

    function GROWABLE_HEAP_U8() {
      if (wasmMemory.buffer != buffer) {
        updateGlobalBufferAndViews(wasmMemory.buffer);
      }

      return HEAPU8;
    }

    function GROWABLE_HEAP_I32() {
      if (wasmMemory.buffer != buffer) {
        updateGlobalBufferAndViews(wasmMemory.buffer);
      }

      return HEAP32;
    }

    function GROWABLE_HEAP_U32() {
      if (wasmMemory.buffer != buffer) {
        updateGlobalBufferAndViews(wasmMemory.buffer);
      }

      return HEAPU32;
    }

    function GROWABLE_HEAP_F64() {
      if (wasmMemory.buffer != buffer) {
        updateGlobalBufferAndViews(wasmMemory.buffer);
      }

      return HEAPF64;
    }

    var Module = typeof WasmBackendModuleThreadedSimd !== "undefined" ? WasmBackendModuleThreadedSimd : {};
    var readyPromiseResolve, readyPromiseReject;
    Module["ready"] = new Promise(function (resolve, reject) {
      readyPromiseResolve = resolve;
      readyPromiseReject = reject;
    });
    var beforeListeners;

    if (typeof process !== "undefined" && process.listeners) {
      beforeListeners = {
        uncaughtException: process.listeners("uncaughtException"),
        unhandledRejection: process.listeners("unhandledRejection")
      };
    }

    var moduleOverrides = {};
    var key;

    for (key in Module) {
      if (Module.hasOwnProperty(key)) {
        moduleOverrides[key] = Module[key];
      }
    }

    var arguments_ = [];
    var thisProgram = "./this.program";

    var quit_ = function (status, toThrow) {
      throw toThrow;
    };

    var ENVIRONMENT_IS_WEB = false;
    var ENVIRONMENT_IS_WORKER = false;
    var ENVIRONMENT_IS_NODE = false;
    var ENVIRONMENT_IS_SHELL = false;
    ENVIRONMENT_IS_WEB = typeof window === "object";
    ENVIRONMENT_IS_WORKER = typeof importScripts === "function";
    ENVIRONMENT_IS_NODE = typeof process === "object" && typeof process.versions === "object" && typeof process.versions.node === "string";
    ENVIRONMENT_IS_SHELL = !ENVIRONMENT_IS_WEB && !ENVIRONMENT_IS_NODE && !ENVIRONMENT_IS_WORKER;
    var ENVIRONMENT_IS_PTHREAD = Module["ENVIRONMENT_IS_PTHREAD"] || false;

    if (ENVIRONMENT_IS_PTHREAD) {
      buffer = Module["buffer"];
    }

    var scriptDirectory = "";

    function locateFile(path) {
      if (Module["locateFile"]) {
        return Module["locateFile"](path, scriptDirectory);
      }

      return scriptDirectory + path;
    }

    var read_, readAsync, readBinary, setWindowTitle;
    var nodeFS;
    var nodePath;

    if (ENVIRONMENT_IS_NODE) {
      if (ENVIRONMENT_IS_WORKER) {
        scriptDirectory = _path.dirname(scriptDirectory) + "/";
      } else {
        scriptDirectory = new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname + "/";
      }

      read_ = function shell_read(filename, binary) {
        if (!nodeFS) nodeFS = _fs;
        if (!nodePath) nodePath = _path;
        filename = nodePath["normalize"](filename);
        return nodeFS["readFileSync"](filename, binary ? null : "utf8");
      };

      readBinary = function readBinary(filename) {
        var ret = read_(filename, true);

        if (!ret.buffer) {
          ret = new Uint8Array(ret);
        }

        assert(ret.buffer);
        return ret;
      };

      if (process["argv"].length > 1) {
        thisProgram = process["argv"][1].replace(/\\/g, "/");
      }

      arguments_ = process["argv"].slice(2);
      process["on"]("uncaughtException", function (ex) {
        if (!(ex instanceof ExitStatus)) {
          throw ex;
        }
      });
      process["on"]("unhandledRejection", abort);

      quit_ = function (status) {
        process["exit"](status);
      };

      Module["inspect"] = function () {
        return "[Emscripten Module object]";
      };

      var nodeWorkerThreads;

      try {
        nodeWorkerThreads = _worker_threads;
      } catch (e) {
        console.error("The \"worker_threads\" module is not supported in this node.js build - perhaps a newer version is needed?");
        throw e;
      }

      _global.Worker = nodeWorkerThreads.Worker;
    } else if (ENVIRONMENT_IS_SHELL) {
      if (typeof read != "undefined") {
        read_ = function shell_read(f) {
          return read(f);
        };
      }

      readBinary = function readBinary(f) {
        var data;

        if (typeof readbuffer === "function") {
          return new Uint8Array(readbuffer(f));
        }

        data = read(f, "binary");
        assert(typeof data === "object");
        return data;
      };

      if (typeof scriptArgs != "undefined") {
        arguments_ = scriptArgs;
      } else if (typeof arguments != "undefined") {
        arguments_ = arguments;
      }

      if (typeof quit === "function") {
        quit_ = function (status) {
          quit(status);
        };
      }

      if (typeof print !== "undefined") {
        if (typeof console === "undefined") _global.console = {};
        console.log = print;
        console.warn = console.error = typeof printErr !== "undefined" ? printErr : print;
      }
    } else if (ENVIRONMENT_IS_WEB || ENVIRONMENT_IS_WORKER) {
      if (ENVIRONMENT_IS_WORKER) {
        scriptDirectory = self.location.href;
      } else if (typeof document !== "undefined" && document.currentScript) {
        scriptDirectory = document.currentScript.src;
      }

      if (typeof _scriptDir !== "undefined" && _scriptDir) {
        scriptDirectory = _scriptDir;
      }

      if (scriptDirectory.indexOf("blob:") !== 0) {
        scriptDirectory = scriptDirectory.substr(0, scriptDirectory.lastIndexOf("/") + 1);
      } else {
        scriptDirectory = "";
      }

      if (ENVIRONMENT_IS_NODE) {
        read_ = function shell_read(filename, binary) {
          if (!nodeFS) nodeFS = _fs;
          if (!nodePath) nodePath = _path;
          filename = nodePath["normalize"](filename);
          return nodeFS["readFileSync"](filename, binary ? null : "utf8");
        };

        readBinary = function readBinary(filename) {
          var ret = read_(filename, true);

          if (!ret.buffer) {
            ret = new Uint8Array(ret);
          }

          assert(ret.buffer);
          return ret;
        };
      } else {
        read_ = function (url) {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, false);
          xhr.send(null);
          return xhr.responseText;
        };

        if (ENVIRONMENT_IS_WORKER) {
          readBinary = function (url) {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url, false);
            xhr.responseType = "arraybuffer";
            xhr.send(null);
            return new Uint8Array(xhr.response);
          };
        }

        readAsync = function (url, onload, onerror) {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.responseType = "arraybuffer";

          xhr.onload = function () {
            if (xhr.status == 200 || xhr.status == 0 && xhr.response) {
              onload(xhr.response);
              return;
            }

            onerror();
          };

          xhr.onerror = onerror;
          xhr.send(null);
        };
      }

      setWindowTitle = function (title) {
        document.title = title;
      };
    } else {}

    if (ENVIRONMENT_IS_NODE) {
      if (typeof performance === "undefined") {
        _global.performance = _perf_hooks.performance;
      }
    }

    var out = Module["print"] || console.log.bind(console);
    var err = Module["printErr"] || console.warn.bind(console);

    for (key in moduleOverrides) {
      if (moduleOverrides.hasOwnProperty(key)) {
        Module[key] = moduleOverrides[key];
      }
    }

    moduleOverrides = null;
    if (Module["arguments"]) arguments_ = Module["arguments"];
    if (Module["thisProgram"]) thisProgram = Module["thisProgram"];
    if (Module["quit"]) quit_ = Module["quit"];

    function warnOnce(text) {
      if (!warnOnce.shown) warnOnce.shown = {};

      if (!warnOnce.shown[text]) {
        warnOnce.shown[text] = 1;
        err(text);
      }
    }

    var Atomics_load = Atomics.load;
    var Atomics_store = Atomics.store;
    var Atomics_compareExchange = Atomics.compareExchange;
    var wasmBinary;
    if (Module["wasmBinary"]) wasmBinary = Module["wasmBinary"];
    var noExitRuntime = Module["noExitRuntime"] || true;

    if (typeof WebAssembly !== "object") {
      abort("no native wasm support detected");
    }

    var wasmMemory;
    var wasmModule;
    var ABORT = false;
    var EXITSTATUS;

    function assert(condition, text) {
      if (!condition) {
        abort("Assertion failed: " + text);
      }
    }

    function getCFunc(ident) {
      var func = Module["_" + ident];
      assert(func, "Cannot call unknown function " + ident + ", make sure it is exported");
      return func;
    }

    function ccall(ident, returnType, argTypes, args, opts) {
      var toC = {
        "string": function (str) {
          var ret = 0;

          if (str !== null && str !== undefined && str !== 0) {
            var len = (str.length << 2) + 1;
            ret = stackAlloc(len);
            stringToUTF8(str, ret, len);
          }

          return ret;
        },
        "array": function (arr) {
          var ret = stackAlloc(arr.length);
          writeArrayToMemory(arr, ret);
          return ret;
        }
      };

      function convertReturnValue(ret) {
        if (returnType === "string") return UTF8ToString(ret);
        if (returnType === "boolean") return Boolean(ret);
        return ret;
      }

      var func = getCFunc(ident);
      var cArgs = [];
      var stack = 0;

      if (args) {
        for (var i = 0; i < args.length; i++) {
          var converter = toC[argTypes[i]];

          if (converter) {
            if (stack === 0) stack = stackSave();
            cArgs[i] = converter(args[i]);
          } else {
            cArgs[i] = args[i];
          }
        }
      }

      var ret = func.apply(null, cArgs);
      ret = convertReturnValue(ret);
      if (stack !== 0) stackRestore(stack);
      return ret;
    }

    function cwrap(ident, returnType, argTypes, opts) {
      argTypes = argTypes || [];
      var numericArgs = argTypes.every(function (type) {
        return type === "number";
      });
      var numericRet = returnType !== "string";

      if (numericRet && numericArgs && !opts) {
        return getCFunc(ident);
      }

      return function () {
        return ccall(ident, returnType, argTypes, arguments, opts);
      };
    }

    function UTF8ArrayToString(heap, idx, maxBytesToRead) {
      var endIdx = idx + maxBytesToRead;
      var str = "";

      while (!(idx >= endIdx)) {
        var u0 = heap[idx++];
        if (!u0) return str;

        if (!(u0 & 128)) {
          str += String.fromCharCode(u0);
          continue;
        }

        var u1 = heap[idx++] & 63;

        if ((u0 & 224) == 192) {
          str += String.fromCharCode((u0 & 31) << 6 | u1);
          continue;
        }

        var u2 = heap[idx++] & 63;

        if ((u0 & 240) == 224) {
          u0 = (u0 & 15) << 12 | u1 << 6 | u2;
        } else {
          u0 = (u0 & 7) << 18 | u1 << 12 | u2 << 6 | heap[idx++] & 63;
        }

        if (u0 < 65536) {
          str += String.fromCharCode(u0);
        } else {
          var ch = u0 - 65536;
          str += String.fromCharCode(55296 | ch >> 10, 56320 | ch & 1023);
        }
      }

      return str;
    }

    function UTF8ToString(ptr, maxBytesToRead) {
      return ptr ? UTF8ArrayToString(GROWABLE_HEAP_U8(), ptr, maxBytesToRead) : "";
    }

    function stringToUTF8Array(str, heap, outIdx, maxBytesToWrite) {
      if (!(maxBytesToWrite > 0)) return 0;
      var startIdx = outIdx;
      var endIdx = outIdx + maxBytesToWrite - 1;

      for (var i = 0; i < str.length; ++i) {
        var u = str.charCodeAt(i);

        if (u >= 55296 && u <= 57343) {
          var u1 = str.charCodeAt(++i);
          u = 65536 + ((u & 1023) << 10) | u1 & 1023;
        }

        if (u <= 127) {
          if (outIdx >= endIdx) break;
          heap[outIdx++] = u;
        } else if (u <= 2047) {
          if (outIdx + 1 >= endIdx) break;
          heap[outIdx++] = 192 | u >> 6;
          heap[outIdx++] = 128 | u & 63;
        } else if (u <= 65535) {
          if (outIdx + 2 >= endIdx) break;
          heap[outIdx++] = 224 | u >> 12;
          heap[outIdx++] = 128 | u >> 6 & 63;
          heap[outIdx++] = 128 | u & 63;
        } else {
          if (outIdx + 3 >= endIdx) break;
          heap[outIdx++] = 240 | u >> 18;
          heap[outIdx++] = 128 | u >> 12 & 63;
          heap[outIdx++] = 128 | u >> 6 & 63;
          heap[outIdx++] = 128 | u & 63;
        }
      }

      heap[outIdx] = 0;
      return outIdx - startIdx;
    }

    function stringToUTF8(str, outPtr, maxBytesToWrite) {
      return stringToUTF8Array(str, GROWABLE_HEAP_U8(), outPtr, maxBytesToWrite);
    }

    function lengthBytesUTF8(str) {
      var len = 0;

      for (var i = 0; i < str.length; ++i) {
        var u = str.charCodeAt(i);
        if (u >= 55296 && u <= 57343) u = 65536 + ((u & 1023) << 10) | str.charCodeAt(++i) & 1023;
        if (u <= 127) ++len;else if (u <= 2047) len += 2;else if (u <= 65535) len += 3;else len += 4;
      }

      return len;
    }

    function writeArrayToMemory(array, buffer) {
      GROWABLE_HEAP_I8().set(array, buffer);
    }

    function alignUp(x, multiple) {
      if (x % multiple > 0) {
        x += multiple - x % multiple;
      }

      return x;
    }

    var buffer, HEAP8, HEAPU8, HEAP16, HEAPU16, HEAP32, HEAPU32, HEAPF32, HEAPF64;

    function updateGlobalBufferAndViews(buf) {
      buffer = buf;
      Module["HEAP8"] = HEAP8 = new Int8Array(buf);
      Module["HEAP16"] = HEAP16 = new Int16Array(buf);
      Module["HEAP32"] = HEAP32 = new Int32Array(buf);
      Module["HEAPU8"] = HEAPU8 = new Uint8Array(buf);
      Module["HEAPU16"] = HEAPU16 = new Uint16Array(buf);
      Module["HEAPU32"] = HEAPU32 = new Uint32Array(buf);
      Module["HEAPF32"] = HEAPF32 = new Float32Array(buf);
      Module["HEAPF64"] = HEAPF64 = new Float64Array(buf);
    }

    var INITIAL_MEMORY = Module["INITIAL_MEMORY"] || 16777216;

    if (ENVIRONMENT_IS_PTHREAD) {
      wasmMemory = Module["wasmMemory"];
      buffer = Module["buffer"];
    } else {
      if (Module["wasmMemory"]) {
        wasmMemory = Module["wasmMemory"];
      } else {
        wasmMemory = new WebAssembly.Memory({
          "initial": INITIAL_MEMORY / 65536,
          "maximum": 2147483648 / 65536,
          "shared": true
        });

        if (!(wasmMemory.buffer instanceof SharedArrayBuffer)) {
          err("requested a shared WebAssembly.Memory but the returned buffer is not a SharedArrayBuffer, indicating that while the browser has SharedArrayBuffer it does not have WebAssembly threads support - you may need to set a flag");

          if (ENVIRONMENT_IS_NODE) {
            console.log("(on node you may need: --experimental-wasm-threads --experimental-wasm-bulk-memory and also use a recent version)");
          }

          throw Error("bad memory");
        }
      }
    }

    if (wasmMemory) {
      buffer = wasmMemory.buffer;
    }

    INITIAL_MEMORY = buffer.byteLength;
    updateGlobalBufferAndViews(buffer);
    var wasmTable;
    var __ATPRERUN__ = [];
    var __ATINIT__ = [];
    var __ATMAIN__ = [];
    var __ATEXIT__ = [];
    var __ATPOSTRUN__ = [];
    var runtimeInitialized = false;
    var runtimeExited = false;
    if (!ENVIRONMENT_IS_PTHREAD) __ATINIT__.push({
      func: function () {
        ___wasm_call_ctors();
      }
    });

    function preRun() {
      if (ENVIRONMENT_IS_PTHREAD) return;

      if (Module["preRun"]) {
        if (typeof Module["preRun"] == "function") Module["preRun"] = [Module["preRun"]];

        while (Module["preRun"].length) {
          addOnPreRun(Module["preRun"].shift());
        }
      }

      callRuntimeCallbacks(__ATPRERUN__);
    }

    function initRuntime() {
      runtimeInitialized = true;
      if (ENVIRONMENT_IS_PTHREAD) return;
      callRuntimeCallbacks(__ATINIT__);
    }

    function preMain() {
      if (ENVIRONMENT_IS_PTHREAD) return;
      callRuntimeCallbacks(__ATMAIN__);
    }

    function exitRuntime() {
      if (ENVIRONMENT_IS_PTHREAD) return;
      runtimeExited = true;
    }

    function postRun() {
      if (ENVIRONMENT_IS_PTHREAD) return;

      if (Module["postRun"]) {
        if (typeof Module["postRun"] == "function") Module["postRun"] = [Module["postRun"]];

        while (Module["postRun"].length) {
          addOnPostRun(Module["postRun"].shift());
        }
      }

      callRuntimeCallbacks(__ATPOSTRUN__);
    }

    function addOnPreRun(cb) {
      __ATPRERUN__.unshift(cb);
    }

    function addOnPostRun(cb) {
      __ATPOSTRUN__.unshift(cb);
    }

    var runDependencies = 0;
    var runDependencyWatcher = null;
    var dependenciesFulfilled = null;

    function addRunDependency(id) {
      assert(!ENVIRONMENT_IS_PTHREAD, "addRunDependency cannot be used in a pthread worker");
      runDependencies++;

      if (Module["monitorRunDependencies"]) {
        Module["monitorRunDependencies"](runDependencies);
      }
    }

    function removeRunDependency(id) {
      runDependencies--;

      if (Module["monitorRunDependencies"]) {
        Module["monitorRunDependencies"](runDependencies);
      }

      if (runDependencies == 0) {
        if (runDependencyWatcher !== null) {
          clearInterval(runDependencyWatcher);
          runDependencyWatcher = null;
        }

        if (dependenciesFulfilled) {
          var callback = dependenciesFulfilled;
          dependenciesFulfilled = null;
          callback();
        }
      }
    }

    Module["preloadedImages"] = {};
    Module["preloadedAudios"] = {};

    function abort(what) {
      if (Module["onAbort"]) {
        Module["onAbort"](what);
      }

      if (ENVIRONMENT_IS_PTHREAD) console.error("Pthread aborting at " + new Error().stack);
      what += "";
      err(what);
      ABORT = true;
      EXITSTATUS = 1;
      what = "abort(" + what + "). Build with -s ASSERTIONS=1 for more info.";
      var e = new WebAssembly.RuntimeError(what);
      readyPromiseReject(e);
      throw e;
    }

    function hasPrefix(str, prefix) {
      return String.prototype.startsWith ? str.startsWith(prefix) : str.indexOf(prefix) === 0;
    }

    var dataURIPrefix = "data:application/octet-stream;base64,";

    function isDataURI(filename) {
      return hasPrefix(filename, dataURIPrefix);
    }

    var fileURIPrefix = "file://";

    function isFileURI(filename) {
      return hasPrefix(filename, fileURIPrefix);
    }

    var wasmBinaryFile = "tfjs-backend-wasm-threaded-simd.wasm";

    if (!isDataURI(wasmBinaryFile)) {
      wasmBinaryFile = locateFile(wasmBinaryFile);
    }

    function getBinary(file) {
      try {
        if (file == wasmBinaryFile && wasmBinary) {
          return new Uint8Array(wasmBinary);
        }

        if (readBinary) {
          return readBinary(file);
        } else {
          throw "both async and sync fetching of the wasm failed";
        }
      } catch (err) {
        abort(err);
      }
    }

    function getBinaryPromise() {
      if (!wasmBinary && (ENVIRONMENT_IS_WEB || ENVIRONMENT_IS_WORKER)) {
        if (typeof fetch === "function" && !isFileURI(wasmBinaryFile)) {
          return fetch(wasmBinaryFile, {
            credentials: "same-origin"
          }).then(function (response) {
            if (!response["ok"]) {
              throw "failed to load wasm binary file at '" + wasmBinaryFile + "'";
            }

            return response["arrayBuffer"]();
          }).catch(function () {
            return getBinary(wasmBinaryFile);
          });
        } else {
          if (readAsync) {
            return new Promise(function (resolve, reject) {
              readAsync(wasmBinaryFile, function (response) {
                resolve(new Uint8Array(response));
              }, reject);
            });
          }
        }
      }

      return Promise.resolve().then(function () {
        return getBinary(wasmBinaryFile);
      });
    }

    function createWasm() {
      var info = {
        "a": asmLibraryArg
      };

      function receiveInstance(instance, module) {
        var exports = instance.exports;
        Module["asm"] = exports;
        wasmTable = Module["asm"]["I"];
        wasmModule = module;

        if (!ENVIRONMENT_IS_PTHREAD) {
          var numWorkersToLoad = PThread.unusedWorkers.length;
          PThread.unusedWorkers.forEach(function (w) {
            PThread.loadWasmModuleToWorker(w, function () {
              if (! --numWorkersToLoad) removeRunDependency("wasm-instantiate");
            });
          });
        }
      }

      if (!ENVIRONMENT_IS_PTHREAD) {
        addRunDependency("wasm-instantiate");
      }

      function receiveInstantiatedSource(output) {
        receiveInstance(output["instance"], output["module"]);
      }

      function instantiateArrayBuffer(receiver) {
        return getBinaryPromise().then(function (binary) {
          return WebAssembly.instantiate(binary, info);
        }).then(receiver, function (reason) {
          err("failed to asynchronously prepare wasm: " + reason);
          abort(reason);
        });
      }

      function instantiateAsync() {
        if (!wasmBinary && typeof WebAssembly.instantiateStreaming === "function" && !isDataURI(wasmBinaryFile) && !isFileURI(wasmBinaryFile) && typeof fetch === "function") {
          return fetch(wasmBinaryFile, {
            credentials: "same-origin"
          }).then(function (response) {
            var result = WebAssembly.instantiateStreaming(response, info);
            return result.then(receiveInstantiatedSource, function (reason) {
              err("wasm streaming compile failed: " + reason);
              err("falling back to ArrayBuffer instantiation");
              return instantiateArrayBuffer(receiveInstantiatedSource);
            });
          });
        } else {
          return instantiateArrayBuffer(receiveInstantiatedSource);
        }
      }

      if (Module["instantiateWasm"]) {
        try {
          var exports = Module["instantiateWasm"](info, receiveInstance);
          return exports;
        } catch (e) {
          err("Module.instantiateWasm callback failed with error: " + e);
          return false;
        }
      }

      instantiateAsync().catch(readyPromiseReject);
      return {};
    }

    var ASM_CONSTS = {
      10664: function () {
        throw "Canceled!";
      },
      10682: function ($0, $1) {
        setTimeout(function () {
          __emscripten_do_dispatch_to_thread($0, $1);
        }, 0);
      }
    };

    function initPthreadsJS() {
      PThread.initRuntime();
    }

    function callRuntimeCallbacks(callbacks) {
      while (callbacks.length > 0) {
        var callback = callbacks.shift();

        if (typeof callback == "function") {
          callback(Module);
          continue;
        }

        var func = callback.func;

        if (typeof func === "number") {
          if (callback.arg === undefined) {
            wasmTable.get(func)();
          } else {
            wasmTable.get(func)(callback.arg);
          }
        } else {
          func(callback.arg === undefined ? null : callback.arg);
        }
      }
    }

    var ERRNO_CODES = {
      EPERM: 63,
      ENOENT: 44,
      ESRCH: 71,
      EINTR: 27,
      EIO: 29,
      ENXIO: 60,
      E2BIG: 1,
      ENOEXEC: 45,
      EBADF: 8,
      ECHILD: 12,
      EAGAIN: 6,
      EWOULDBLOCK: 6,
      ENOMEM: 48,
      EACCES: 2,
      EFAULT: 21,
      ENOTBLK: 105,
      EBUSY: 10,
      EEXIST: 20,
      EXDEV: 75,
      ENODEV: 43,
      ENOTDIR: 54,
      EISDIR: 31,
      EINVAL: 28,
      ENFILE: 41,
      EMFILE: 33,
      ENOTTY: 59,
      ETXTBSY: 74,
      EFBIG: 22,
      ENOSPC: 51,
      ESPIPE: 70,
      EROFS: 69,
      EMLINK: 34,
      EPIPE: 64,
      EDOM: 18,
      ERANGE: 68,
      ENOMSG: 49,
      EIDRM: 24,
      ECHRNG: 106,
      EL2NSYNC: 156,
      EL3HLT: 107,
      EL3RST: 108,
      ELNRNG: 109,
      EUNATCH: 110,
      ENOCSI: 111,
      EL2HLT: 112,
      EDEADLK: 16,
      ENOLCK: 46,
      EBADE: 113,
      EBADR: 114,
      EXFULL: 115,
      ENOANO: 104,
      EBADRQC: 103,
      EBADSLT: 102,
      EDEADLOCK: 16,
      EBFONT: 101,
      ENOSTR: 100,
      ENODATA: 116,
      ETIME: 117,
      ENOSR: 118,
      ENONET: 119,
      ENOPKG: 120,
      EREMOTE: 121,
      ENOLINK: 47,
      EADV: 122,
      ESRMNT: 123,
      ECOMM: 124,
      EPROTO: 65,
      EMULTIHOP: 36,
      EDOTDOT: 125,
      EBADMSG: 9,
      ENOTUNIQ: 126,
      EBADFD: 127,
      EREMCHG: 128,
      ELIBACC: 129,
      ELIBBAD: 130,
      ELIBSCN: 131,
      ELIBMAX: 132,
      ELIBEXEC: 133,
      ENOSYS: 52,
      ENOTEMPTY: 55,
      ENAMETOOLONG: 37,
      ELOOP: 32,
      EOPNOTSUPP: 138,
      EPFNOSUPPORT: 139,
      ECONNRESET: 15,
      ENOBUFS: 42,
      EAFNOSUPPORT: 5,
      EPROTOTYPE: 67,
      ENOTSOCK: 57,
      ENOPROTOOPT: 50,
      ESHUTDOWN: 140,
      ECONNREFUSED: 14,
      EADDRINUSE: 3,
      ECONNABORTED: 13,
      ENETUNREACH: 40,
      ENETDOWN: 38,
      ETIMEDOUT: 73,
      EHOSTDOWN: 142,
      EHOSTUNREACH: 23,
      EINPROGRESS: 26,
      EALREADY: 7,
      EDESTADDRREQ: 17,
      EMSGSIZE: 35,
      EPROTONOSUPPORT: 66,
      ESOCKTNOSUPPORT: 137,
      EADDRNOTAVAIL: 4,
      ENETRESET: 39,
      EISCONN: 30,
      ENOTCONN: 53,
      ETOOMANYREFS: 141,
      EUSERS: 136,
      EDQUOT: 19,
      ESTALE: 72,
      ENOTSUP: 138,
      ENOMEDIUM: 148,
      EILSEQ: 25,
      EOVERFLOW: 61,
      ECANCELED: 11,
      ENOTRECOVERABLE: 56,
      EOWNERDEAD: 62,
      ESTRPIPE: 135
    };

    function _emscripten_futex_wake(addr, count) {
      if (addr <= 0 || addr > GROWABLE_HEAP_I8().length || addr & 3 != 0 || count < 0) return -28;
      if (count == 0) return 0;
      if (count >= 2147483647) count = Infinity;
      var mainThreadWaitAddress = Atomics.load(GROWABLE_HEAP_I32(), __emscripten_main_thread_futex >> 2);
      var mainThreadWoken = 0;

      if (mainThreadWaitAddress == addr) {
        var loadedAddr = Atomics.compareExchange(GROWABLE_HEAP_I32(), __emscripten_main_thread_futex >> 2, mainThreadWaitAddress, 0);

        if (loadedAddr == mainThreadWaitAddress) {
          --count;
          mainThreadWoken = 1;
          if (count <= 0) return 1;
        }
      }

      var ret = Atomics.notify(GROWABLE_HEAP_I32(), addr >> 2, count);
      if (ret >= 0) return ret + mainThreadWoken;
      throw "Atomics.notify returned an unexpected value " + ret;
    }

    Module["_emscripten_futex_wake"] = _emscripten_futex_wake;

    function killThread(pthread_ptr) {
      if (ENVIRONMENT_IS_PTHREAD) throw "Internal Error! killThread() can only ever be called from main application thread!";
      if (!pthread_ptr) throw "Internal Error! Null pthread_ptr in killThread!";
      GROWABLE_HEAP_I32()[pthread_ptr + 12 >> 2] = 0;
      var pthread = PThread.pthreads[pthread_ptr];
      pthread.worker.terminate();
      PThread.freeThreadData(pthread);
      PThread.runningWorkers.splice(PThread.runningWorkers.indexOf(pthread.worker), 1);
      pthread.worker.pthread = undefined;
    }

    function cancelThread(pthread_ptr) {
      if (ENVIRONMENT_IS_PTHREAD) throw "Internal Error! cancelThread() can only ever be called from main application thread!";
      if (!pthread_ptr) throw "Internal Error! Null pthread_ptr in cancelThread!";
      var pthread = PThread.pthreads[pthread_ptr];
      pthread.worker.postMessage({
        "cmd": "cancel"
      });
    }

    function cleanupThread(pthread_ptr) {
      if (ENVIRONMENT_IS_PTHREAD) throw "Internal Error! cleanupThread() can only ever be called from main application thread!";
      if (!pthread_ptr) throw "Internal Error! Null pthread_ptr in cleanupThread!";
      var pthread = PThread.pthreads[pthread_ptr];

      if (pthread) {
        GROWABLE_HEAP_I32()[pthread_ptr + 12 >> 2] = 0;
        var worker = pthread.worker;
        PThread.returnWorkerToPool(worker);
      }
    }

    var PThread = {
      unusedWorkers: [],
      runningWorkers: [],
      initMainThreadBlock: function () {
        var pthreadPoolSize = 8;

        for (var i = 0; i < pthreadPoolSize; ++i) {
          PThread.allocateUnusedWorker();
        }
      },
      initRuntime: function () {
        var tb = _malloc(228);

        for (var i = 0; i < 228 / 4; ++i) GROWABLE_HEAP_U32()[tb / 4 + i] = 0;

        GROWABLE_HEAP_I32()[tb + 12 >> 2] = tb;
        var headPtr = tb + 152;
        GROWABLE_HEAP_I32()[headPtr >> 2] = headPtr;

        var tlsMemory = _malloc(512);

        for (var i = 0; i < 128; ++i) GROWABLE_HEAP_U32()[tlsMemory / 4 + i] = 0;

        Atomics.store(GROWABLE_HEAP_U32(), tb + 100 >> 2, tlsMemory);
        Atomics.store(GROWABLE_HEAP_U32(), tb + 40 >> 2, tb);

        __emscripten_thread_init(tb, !ENVIRONMENT_IS_WORKER, 1);

        _emscripten_register_main_browser_thread_id(tb);
      },
      initWorker: function () {},
      pthreads: {},
      threadExitHandlers: [],
      setThreadStatus: function () {},
      runExitHandlers: function () {
        while (PThread.threadExitHandlers.length > 0) {
          PThread.threadExitHandlers.pop()();
        }

        if (ENVIRONMENT_IS_PTHREAD && _pthread_self()) ___pthread_tsd_run_dtors();
      },
      runExitHandlersAndDeinitThread: function (tb, exitCode) {
        Atomics.store(GROWABLE_HEAP_U32(), tb + 56 >> 2, 1);
        Atomics.store(GROWABLE_HEAP_U32(), tb + 60 >> 2, 0);
        PThread.runExitHandlers();
        Atomics.store(GROWABLE_HEAP_U32(), tb + 4 >> 2, exitCode);
        Atomics.store(GROWABLE_HEAP_U32(), tb + 0 >> 2, 1);

        _emscripten_futex_wake(tb + 0, 2147483647);

        __emscripten_thread_init(0, 0, 0);
      },
      threadExit: function (exitCode) {
        var tb = _pthread_self();

        if (tb) {
          PThread.runExitHandlersAndDeinitThread(tb, exitCode);

          if (ENVIRONMENT_IS_PTHREAD) {
            postMessage({
              "cmd": "exit"
            });
          }
        }
      },
      threadCancel: function () {
        PThread.runExitHandlersAndDeinitThread(_pthread_self(), -1);
        postMessage({
          "cmd": "cancelDone"
        });
      },
      terminateAllThreads: function () {
        for (var t in PThread.pthreads) {
          var pthread = PThread.pthreads[t];

          if (pthread && pthread.worker) {
            PThread.returnWorkerToPool(pthread.worker);
          }
        }

        PThread.pthreads = {};

        for (var i = 0; i < PThread.unusedWorkers.length; ++i) {
          var worker = PThread.unusedWorkers[i];
          worker.terminate();
        }

        PThread.unusedWorkers = [];

        for (var i = 0; i < PThread.runningWorkers.length; ++i) {
          var worker = PThread.runningWorkers[i];
          var pthread = worker.pthread;
          PThread.freeThreadData(pthread);
          worker.terminate();
        }

        PThread.runningWorkers = [];
      },
      freeThreadData: function (pthread) {
        if (!pthread) return;

        if (pthread.threadInfoStruct) {
          var tlsMemory = GROWABLE_HEAP_I32()[pthread.threadInfoStruct + 100 >> 2];
          GROWABLE_HEAP_I32()[pthread.threadInfoStruct + 100 >> 2] = 0;

          _free(tlsMemory);

          _free(pthread.threadInfoStruct);
        }

        pthread.threadInfoStruct = 0;
        if (pthread.allocatedOwnStack && pthread.stackBase) _free(pthread.stackBase);
        pthread.stackBase = 0;
        if (pthread.worker) pthread.worker.pthread = null;
      },
      returnWorkerToPool: function (worker) {
        PThread.runWithoutMainThreadQueuedCalls(function () {
          delete PThread.pthreads[worker.pthread.threadInfoStruct];
          PThread.unusedWorkers.push(worker);
          PThread.runningWorkers.splice(PThread.runningWorkers.indexOf(worker), 1);
          PThread.freeThreadData(worker.pthread);
          worker.pthread = undefined;
        });
      },
      runWithoutMainThreadQueuedCalls: function (func) {
        GROWABLE_HEAP_I32()[__emscripten_allow_main_runtime_queued_calls >> 2] = 0;

        try {
          func();
        } finally {
          GROWABLE_HEAP_I32()[__emscripten_allow_main_runtime_queued_calls >> 2] = 1;
        }
      },
      receiveObjectTransfer: function (data) {},
      loadWasmModuleToWorker: function (worker, onFinishedLoading) {
        worker.onmessage = function (e) {
          var d = e["data"];
          var cmd = d["cmd"];
          if (worker.pthread) PThread.currentProxiedOperationCallerThread = worker.pthread.threadInfoStruct;

          if (d["targetThread"] && d["targetThread"] != _pthread_self()) {
            var thread = PThread.pthreads[d.targetThread];

            if (thread) {
              thread.worker.postMessage(e.data, d["transferList"]);
            } else {
              console.error("Internal error! Worker sent a message \"" + cmd + "\" to target pthread " + d["targetThread"] + ", but that thread no longer exists!");
            }

            PThread.currentProxiedOperationCallerThread = undefined;
            return;
          }

          if (cmd === "processQueuedMainThreadWork") {
            _emscripten_main_thread_process_queued_calls();
          } else if (cmd === "spawnThread") {
            spawnThread(e.data);
          } else if (cmd === "cleanupThread") {
            cleanupThread(d["thread"]);
          } else if (cmd === "killThread") {
            killThread(d["thread"]);
          } else if (cmd === "cancelThread") {
            cancelThread(d["thread"]);
          } else if (cmd === "loaded") {
            worker.loaded = true;
            if (onFinishedLoading) onFinishedLoading(worker);

            if (worker.runPthread) {
              worker.runPthread();
              delete worker.runPthread;
            }
          } else if (cmd === "print") {
            out("Thread " + d["threadId"] + ": " + d["text"]);
          } else if (cmd === "printErr") {
            err("Thread " + d["threadId"] + ": " + d["text"]);
          } else if (cmd === "alert") {
            alert("Thread " + d["threadId"] + ": " + d["text"]);
          } else if (cmd === "exit") {
            var detached = worker.pthread && Atomics.load(GROWABLE_HEAP_U32(), worker.pthread.threadInfoStruct + 64 >> 2);

            if (detached) {
              PThread.returnWorkerToPool(worker);
            }
          } else if (cmd === "exitProcess") {
            try {
              exit(d["returnCode"]);
            } catch (e) {
              if (e instanceof ExitStatus) return;
              throw e;
            }
          } else if (cmd === "cancelDone") {
            PThread.returnWorkerToPool(worker);
          } else if (cmd === "objectTransfer") {
            PThread.receiveObjectTransfer(e.data);
          } else if (e.data.target === "setimmediate") {
            worker.postMessage(e.data);
          } else {
            err("worker sent an unknown command " + cmd);
          }

          PThread.currentProxiedOperationCallerThread = undefined;
        };

        worker.onerror = function (e) {
          err("pthread sent an error! " + e.filename + ":" + e.lineno + ": " + e.message);
        };

        if (ENVIRONMENT_IS_NODE) {
          worker.on("message", function (data) {
            worker.onmessage({
              data: data
            });
          });
          worker.on("error", function (data) {
            worker.onerror(data);
          });
          worker.on("exit", function (data) {});
        }

        worker.postMessage({
          "cmd": "load",
          "urlOrBlob": Module["mainScriptUrlOrBlob"] || _scriptDir,
          "wasmMemory": wasmMemory,
          "wasmModule": wasmModule
        });
      },
      allocateUnusedWorker: function () {
        var pthreadMainJs = locateFile("tfjs-backend-wasm-threaded-simd.worker.js");
        PThread.unusedWorkers.push(new Worker(pthreadMainJs));
      },
      getNewWorker: function () {
        if (PThread.unusedWorkers.length == 0) {
          PThread.allocateUnusedWorker();
          PThread.loadWasmModuleToWorker(PThread.unusedWorkers[0]);
        }

        if (PThread.unusedWorkers.length > 0) return PThread.unusedWorkers.pop();else return null;
      },
      busySpinWait: function (msecs) {
        var t = performance.now() + msecs;

        while (performance.now() < t) {}
      }
    };

    function establishStackSpace(stackTop, stackMax) {
      _emscripten_stack_set_limits(stackTop, stackMax);

      stackRestore(stackTop);
    }

    Module["establishStackSpace"] = establishStackSpace;

    function getNoExitRuntime() {
      return noExitRuntime;
    }

    Module["getNoExitRuntime"] = getNoExitRuntime;

    function invokeEntryPoint(ptr, arg) {
      return wasmTable.get(ptr)(arg);
    }

    Module["invokeEntryPoint"] = invokeEntryPoint;

    function ___assert_fail(condition, filename, line, func) {
      abort("Assertion failed: " + UTF8ToString(condition) + ", at: " + [filename ? UTF8ToString(filename) : "unknown filename", line, func ? UTF8ToString(func) : "unknown function"]);
    }

    function ___call_main(argc, argv) {
      var returnCode = _main(argc, argv);
    }

    var _emscripten_get_now;

    if (ENVIRONMENT_IS_NODE) {
      _emscripten_get_now = function () {
        var t = process["hrtime"]();
        return t[0] * 1000 + t[1] / 1000000;
      };
    } else if (ENVIRONMENT_IS_PTHREAD) {
      _emscripten_get_now = function () {
        return performance.now() - Module["__performance_now_clock_drift"];
      };
    } else if (typeof dateNow !== "undefined") {
      _emscripten_get_now = dateNow;
    } else _emscripten_get_now = function () {
      return performance.now();
    };

    function setErrNo(value) {
      GROWABLE_HEAP_I32()[___errno_location() >> 2] = value;
      return value;
    }

    function _atexit(func, arg) {
      if (ENVIRONMENT_IS_PTHREAD) return _emscripten_proxy_to_main_thread_js(1, 1, func, arg);
    }

    function __emscripten_notify_thread_queue(targetThreadId, mainThreadId) {
      if (targetThreadId == mainThreadId) {
        postMessage({
          "cmd": "processQueuedMainThreadWork"
        });
      } else if (ENVIRONMENT_IS_PTHREAD) {
        postMessage({
          "targetThread": targetThreadId,
          "cmd": "processThreadQueue"
        });
      } else {
        var pthread = PThread.pthreads[targetThreadId];
        var worker = pthread && pthread.worker;

        if (!worker) {
          return;
        }

        worker.postMessage({
          "cmd": "processThreadQueue"
        });
      }

      return 1;
    }

    function _abort() {
      abort();
    }

    function _emscripten_asm_const_int(code, sigPtr, argbuf) {
      var args = readAsmConstArgs(sigPtr, argbuf);
      return ASM_CONSTS[code].apply(null, args);
    }

    function _emscripten_conditional_set_current_thread_status(expectedStatus, newStatus) {}

    function _emscripten_futex_wait(addr, val, timeout) {
      if (addr <= 0 || addr > GROWABLE_HEAP_I8().length || addr & 3 != 0) return -28;

      if (!ENVIRONMENT_IS_WEB) {
        var ret = Atomics.wait(GROWABLE_HEAP_I32(), addr >> 2, val, timeout);
        if (ret === "timed-out") return -73;
        if (ret === "not-equal") return -6;
        if (ret === "ok") return 0;
        throw "Atomics.wait returned an unexpected value " + ret;
      } else {
        if (Atomics.load(GROWABLE_HEAP_I32(), addr >> 2) != val) {
          return -6;
        }

        var tNow = performance.now();
        var tEnd = tNow + timeout;
        var lastAddr = Atomics.exchange(GROWABLE_HEAP_I32(), __emscripten_main_thread_futex >> 2, addr);

        while (1) {
          tNow = performance.now();

          if (tNow > tEnd) {
            lastAddr = Atomics.exchange(GROWABLE_HEAP_I32(), __emscripten_main_thread_futex >> 2, 0);
            return -73;
          }

          lastAddr = Atomics.exchange(GROWABLE_HEAP_I32(), __emscripten_main_thread_futex >> 2, 0);

          if (lastAddr == 0) {
            break;
          }

          _emscripten_main_thread_process_queued_calls();

          if (Atomics.load(GROWABLE_HEAP_I32(), addr >> 2) != val) {
            return -6;
          }

          lastAddr = Atomics.exchange(GROWABLE_HEAP_I32(), __emscripten_main_thread_futex >> 2, addr);
        }

        return 0;
      }
    }

    function _emscripten_memcpy_big(dest, src, num) {
      GROWABLE_HEAP_U8().copyWithin(dest, src, src + num);
    }

    function _emscripten_num_logical_cores() {
      if (ENVIRONMENT_IS_NODE) return _os.cpus().length;
      return navigator["hardwareConcurrency"];
    }

    function _emscripten_proxy_to_main_thread_js(index, sync) {
      var numCallArgs = arguments.length - 2;
      var stack = stackSave();
      var serializedNumCallArgs = numCallArgs;
      var args = stackAlloc(serializedNumCallArgs * 8);
      var b = args >> 3;

      for (var i = 0; i < numCallArgs; i++) {
        var arg = arguments[2 + i];
        GROWABLE_HEAP_F64()[b + i] = arg;
      }

      var ret = _emscripten_run_in_main_runtime_thread_js(index, serializedNumCallArgs, args, sync);

      stackRestore(stack);
      return ret;
    }

    var _emscripten_receive_on_main_thread_js_callArgs = [];
    var readAsmConstArgsArray = [];

    function readAsmConstArgs(sigPtr, buf) {
      readAsmConstArgsArray.length = 0;
      var ch;
      buf >>= 2;

      while (ch = GROWABLE_HEAP_U8()[sigPtr++]) {
        var double = ch < 105;
        if (double && buf & 1) buf++;
        readAsmConstArgsArray.push(double ? GROWABLE_HEAP_F64()[buf++ >> 1] : GROWABLE_HEAP_I32()[buf]);
        ++buf;
      }

      return readAsmConstArgsArray;
    }

    function _emscripten_receive_on_main_thread_js(index, numCallArgs, args) {
      _emscripten_receive_on_main_thread_js_callArgs.length = numCallArgs;
      var b = args >> 3;

      for (var i = 0; i < numCallArgs; i++) {
        _emscripten_receive_on_main_thread_js_callArgs[i] = GROWABLE_HEAP_F64()[b + i];
      }

      var isEmAsmConst = index < 0;
      var func = !isEmAsmConst ? proxiedFunctionTable[index] : ASM_CONSTS[-index - 1];
      return func.apply(null, _emscripten_receive_on_main_thread_js_callArgs);
    }

    function _emscripten_get_heap_size() {
      return GROWABLE_HEAP_U8().length;
    }

    function emscripten_realloc_buffer(size) {
      try {
        wasmMemory.grow(size - buffer.byteLength + 65535 >>> 16);
        updateGlobalBufferAndViews(wasmMemory.buffer);
        return 1;
      } catch (e) {}
    }

    function _emscripten_resize_heap(requestedSize) {
      var oldSize = _emscripten_get_heap_size();

      if (requestedSize <= oldSize) {
        return false;
      }

      var maxHeapSize = 2147483648;

      if (requestedSize > maxHeapSize) {
        return false;
      }

      for (var cutDown = 1; cutDown <= 4; cutDown *= 2) {
        var overGrownHeapSize = oldSize * (1 + 0.2 / cutDown);
        overGrownHeapSize = Math.min(overGrownHeapSize, requestedSize + 100663296);
        var newSize = Math.min(maxHeapSize, alignUp(Math.max(requestedSize, overGrownHeapSize), 65536));
        var replacement = emscripten_realloc_buffer(newSize);

        if (replacement) {
          return true;
        }
      }

      return false;
    }

    var JSEvents = {
      inEventHandler: 0,
      removeAllEventListeners: function () {
        for (var i = JSEvents.eventHandlers.length - 1; i >= 0; --i) {
          JSEvents._removeHandler(i);
        }

        JSEvents.eventHandlers = [];
        JSEvents.deferredCalls = [];
      },
      registerRemoveEventListeners: function () {
        if (!JSEvents.removeEventListenersRegistered) {
          __ATEXIT__.push(JSEvents.removeAllEventListeners);

          JSEvents.removeEventListenersRegistered = true;
        }
      },
      deferredCalls: [],
      deferCall: function (targetFunction, precedence, argsList) {
        function arraysHaveEqualContent(arrA, arrB) {
          if (arrA.length != arrB.length) return false;

          for (var i in arrA) {
            if (arrA[i] != arrB[i]) return false;
          }

          return true;
        }

        for (var i in JSEvents.deferredCalls) {
          var call = JSEvents.deferredCalls[i];

          if (call.targetFunction == targetFunction && arraysHaveEqualContent(call.argsList, argsList)) {
            return;
          }
        }

        JSEvents.deferredCalls.push({
          targetFunction: targetFunction,
          precedence: precedence,
          argsList: argsList
        });
        JSEvents.deferredCalls.sort(function (x, y) {
          return x.precedence < y.precedence;
        });
      },
      removeDeferredCalls: function (targetFunction) {
        for (var i = 0; i < JSEvents.deferredCalls.length; ++i) {
          if (JSEvents.deferredCalls[i].targetFunction == targetFunction) {
            JSEvents.deferredCalls.splice(i, 1);
            --i;
          }
        }
      },
      canPerformEventHandlerRequests: function () {
        return JSEvents.inEventHandler && JSEvents.currentEventHandler.allowsDeferredCalls;
      },
      runDeferredCalls: function () {
        if (!JSEvents.canPerformEventHandlerRequests()) {
          return;
        }

        for (var i = 0; i < JSEvents.deferredCalls.length; ++i) {
          var call = JSEvents.deferredCalls[i];
          JSEvents.deferredCalls.splice(i, 1);
          --i;
          call.targetFunction.apply(null, call.argsList);
        }
      },
      eventHandlers: [],
      removeAllHandlersOnTarget: function (target, eventTypeString) {
        for (var i = 0; i < JSEvents.eventHandlers.length; ++i) {
          if (JSEvents.eventHandlers[i].target == target && (!eventTypeString || eventTypeString == JSEvents.eventHandlers[i].eventTypeString)) {
            JSEvents._removeHandler(i--);
          }
        }
      },
      _removeHandler: function (i) {
        var h = JSEvents.eventHandlers[i];
        h.target.removeEventListener(h.eventTypeString, h.eventListenerFunc, h.useCapture);
        JSEvents.eventHandlers.splice(i, 1);
      },
      registerOrRemoveHandler: function (eventHandler) {
        var jsEventHandler = function jsEventHandler(event) {
          ++JSEvents.inEventHandler;
          JSEvents.currentEventHandler = eventHandler;
          JSEvents.runDeferredCalls();
          eventHandler.handlerFunc(event);
          JSEvents.runDeferredCalls();
          --JSEvents.inEventHandler;
        };

        if (eventHandler.callbackfunc) {
          eventHandler.eventListenerFunc = jsEventHandler;
          eventHandler.target.addEventListener(eventHandler.eventTypeString, jsEventHandler, eventHandler.useCapture);
          JSEvents.eventHandlers.push(eventHandler);
          JSEvents.registerRemoveEventListeners();
        } else {
          for (var i = 0; i < JSEvents.eventHandlers.length; ++i) {
            if (JSEvents.eventHandlers[i].target == eventHandler.target && JSEvents.eventHandlers[i].eventTypeString == eventHandler.eventTypeString) {
              JSEvents._removeHandler(i--);
            }
          }
        }
      },
      queueEventHandlerOnThread_iiii: function (targetThread, eventHandlerFunc, eventTypeId, eventData, userData) {
        var stackTop = stackSave();
        var varargs = stackAlloc(12);
        GROWABLE_HEAP_I32()[varargs >> 2] = eventTypeId;
        GROWABLE_HEAP_I32()[varargs + 4 >> 2] = eventData;
        GROWABLE_HEAP_I32()[varargs + 8 >> 2] = userData;

        __emscripten_call_on_thread(0, targetThread, 637534208, eventHandlerFunc, eventData, varargs);

        stackRestore(stackTop);
      },
      getTargetThreadForEventCallback: function (targetThread) {
        switch (targetThread) {
          case 1:
            return 0;

          case 2:
            return PThread.currentProxiedOperationCallerThread;

          default:
            return targetThread;
        }
      },
      getNodeNameForTarget: function (target) {
        if (!target) return "";
        if (target == window) return "#window";
        if (target == screen) return "#screen";
        return target && target.nodeName ? target.nodeName : "";
      },
      fullscreenEnabled: function () {
        return document.fullscreenEnabled || document.webkitFullscreenEnabled;
      }
    };

    function stringToNewUTF8(jsString) {
      var length = lengthBytesUTF8(jsString) + 1;

      var cString = _malloc(length);

      stringToUTF8(jsString, cString, length);
      return cString;
    }

    function _emscripten_set_offscreencanvas_size_on_target_thread_js(targetThread, targetCanvas, width, height) {
      var stackTop = stackSave();
      var varargs = stackAlloc(12);
      var targetCanvasPtr = 0;

      if (targetCanvas) {
        targetCanvasPtr = stringToNewUTF8(targetCanvas);
      }

      GROWABLE_HEAP_I32()[varargs >> 2] = targetCanvasPtr;
      GROWABLE_HEAP_I32()[varargs + 4 >> 2] = width;
      GROWABLE_HEAP_I32()[varargs + 8 >> 2] = height;

      __emscripten_call_on_thread(0, targetThread, 657457152, 0, targetCanvasPtr, varargs);

      stackRestore(stackTop);
    }

    function _emscripten_set_offscreencanvas_size_on_target_thread(targetThread, targetCanvas, width, height) {
      targetCanvas = targetCanvas ? UTF8ToString(targetCanvas) : "";

      _emscripten_set_offscreencanvas_size_on_target_thread_js(targetThread, targetCanvas, width, height);
    }

    function maybeCStringToJsString(cString) {
      return cString > 2 ? UTF8ToString(cString) : cString;
    }

    var specialHTMLTargets = [0, typeof document !== "undefined" ? document : 0, typeof window !== "undefined" ? window : 0];

    function findEventTarget(target) {
      target = maybeCStringToJsString(target);
      var domElement = specialHTMLTargets[target] || (typeof document !== "undefined" ? document.querySelector(target) : undefined);
      return domElement;
    }

    function findCanvasEventTarget(target) {
      return findEventTarget(target);
    }

    function _emscripten_set_canvas_element_size_calling_thread(target, width, height) {
      var canvas = findCanvasEventTarget(target);
      if (!canvas) return -4;

      if (canvas.canvasSharedPtr) {
        GROWABLE_HEAP_I32()[canvas.canvasSharedPtr >> 2] = width;
        GROWABLE_HEAP_I32()[canvas.canvasSharedPtr + 4 >> 2] = height;
      }

      if (canvas.offscreenCanvas || !canvas.controlTransferredOffscreen) {
        if (canvas.offscreenCanvas) canvas = canvas.offscreenCanvas;
        var autoResizeViewport = false;

        if (canvas.GLctxObject && canvas.GLctxObject.GLctx) {
          var prevViewport = canvas.GLctxObject.GLctx.getParameter(2978);
          autoResizeViewport = prevViewport[0] === 0 && prevViewport[1] === 0 && prevViewport[2] === canvas.width && prevViewport[3] === canvas.height;
        }

        canvas.width = width;
        canvas.height = height;

        if (autoResizeViewport) {
          canvas.GLctxObject.GLctx.viewport(0, 0, width, height);
        }
      } else if (canvas.canvasSharedPtr) {
        var targetThread = GROWABLE_HEAP_I32()[canvas.canvasSharedPtr + 8 >> 2];

        _emscripten_set_offscreencanvas_size_on_target_thread(targetThread, target, width, height);

        return 1;
      } else {
        return -4;
      }

      return 0;
    }

    function _emscripten_set_canvas_element_size_main_thread(target, width, height) {
      if (ENVIRONMENT_IS_PTHREAD) return _emscripten_proxy_to_main_thread_js(2, 1, target, width, height);
      return _emscripten_set_canvas_element_size_calling_thread(target, width, height);
    }

    function _emscripten_set_canvas_element_size(target, width, height) {
      var canvas = findCanvasEventTarget(target);

      if (canvas) {
        return _emscripten_set_canvas_element_size_calling_thread(target, width, height);
      } else {
        return _emscripten_set_canvas_element_size_main_thread(target, width, height);
      }
    }

    function _emscripten_set_current_thread_status(newStatus) {}

    function _emscripten_set_thread_name(threadId, name) {}

    function __webgl_enable_ANGLE_instanced_arrays(ctx) {
      var ext = ctx.getExtension("ANGLE_instanced_arrays");

      if (ext) {
        ctx["vertexAttribDivisor"] = function (index, divisor) {
          ext["vertexAttribDivisorANGLE"](index, divisor);
        };

        ctx["drawArraysInstanced"] = function (mode, first, count, primcount) {
          ext["drawArraysInstancedANGLE"](mode, first, count, primcount);
        };

        ctx["drawElementsInstanced"] = function (mode, count, type, indices, primcount) {
          ext["drawElementsInstancedANGLE"](mode, count, type, indices, primcount);
        };

        return 1;
      }
    }

    function __webgl_enable_OES_vertex_array_object(ctx) {
      var ext = ctx.getExtension("OES_vertex_array_object");

      if (ext) {
        ctx["createVertexArray"] = function () {
          return ext["createVertexArrayOES"]();
        };

        ctx["deleteVertexArray"] = function (vao) {
          ext["deleteVertexArrayOES"](vao);
        };

        ctx["bindVertexArray"] = function (vao) {
          ext["bindVertexArrayOES"](vao);
        };

        ctx["isVertexArray"] = function (vao) {
          return ext["isVertexArrayOES"](vao);
        };

        return 1;
      }
    }

    function __webgl_enable_WEBGL_draw_buffers(ctx) {
      var ext = ctx.getExtension("WEBGL_draw_buffers");

      if (ext) {
        ctx["drawBuffers"] = function (n, bufs) {
          ext["drawBuffersWEBGL"](n, bufs);
        };

        return 1;
      }
    }

    function __webgl_enable_WEBGL_multi_draw(ctx) {
      return !!(ctx.multiDrawWebgl = ctx.getExtension("WEBGL_multi_draw"));
    }

    var GL = {
      counter: 1,
      buffers: [],
      programs: [],
      framebuffers: [],
      renderbuffers: [],
      textures: [],
      uniforms: [],
      shaders: [],
      vaos: [],
      contexts: {},
      offscreenCanvases: {},
      timerQueriesEXT: [],
      programInfos: {},
      stringCache: {},
      unpackAlignment: 4,
      recordError: function recordError(errorCode) {
        if (!GL.lastError) {
          GL.lastError = errorCode;
        }
      },
      getNewId: function (table) {
        var ret = GL.counter++;

        for (var i = table.length; i < ret; i++) {
          table[i] = null;
        }

        return ret;
      },
      getSource: function (shader, count, string, length) {
        var source = "";

        for (var i = 0; i < count; ++i) {
          var len = length ? GROWABLE_HEAP_I32()[length + i * 4 >> 2] : -1;
          source += UTF8ToString(GROWABLE_HEAP_I32()[string + i * 4 >> 2], len < 0 ? undefined : len);
        }

        return source;
      },
      createContext: function (canvas, webGLContextAttributes) {
        var ctx = canvas.getContext("webgl", webGLContextAttributes);
        if (!ctx) return 0;
        var handle = GL.registerContext(ctx, webGLContextAttributes);
        return handle;
      },
      registerContext: function (ctx, webGLContextAttributes) {
        var handle = _malloc(8);

        GROWABLE_HEAP_I32()[handle + 4 >> 2] = _pthread_self();
        var context = {
          handle: handle,
          attributes: webGLContextAttributes,
          version: webGLContextAttributes.majorVersion,
          GLctx: ctx
        };
        if (ctx.canvas) ctx.canvas.GLctxObject = context;
        GL.contexts[handle] = context;

        if (typeof webGLContextAttributes.enableExtensionsByDefault === "undefined" || webGLContextAttributes.enableExtensionsByDefault) {
          GL.initExtensions(context);
        }

        return handle;
      },
      makeContextCurrent: function (contextHandle) {
        GL.currentContext = GL.contexts[contextHandle];
        Module.ctx = GLctx = GL.currentContext && GL.currentContext.GLctx;
        return !(contextHandle && !GLctx);
      },
      getContext: function (contextHandle) {
        return GL.contexts[contextHandle];
      },
      deleteContext: function (contextHandle) {
        if (GL.currentContext === GL.contexts[contextHandle]) GL.currentContext = null;
        if (typeof JSEvents === "object") JSEvents.removeAllHandlersOnTarget(GL.contexts[contextHandle].GLctx.canvas);
        if (GL.contexts[contextHandle] && GL.contexts[contextHandle].GLctx.canvas) GL.contexts[contextHandle].GLctx.canvas.GLctxObject = undefined;

        _free(GL.contexts[contextHandle].handle);

        GL.contexts[contextHandle] = null;
      },
      initExtensions: function (context) {
        if (!context) context = GL.currentContext;
        if (context.initExtensionsDone) return;
        context.initExtensionsDone = true;
        var GLctx = context.GLctx;

        __webgl_enable_ANGLE_instanced_arrays(GLctx);

        __webgl_enable_OES_vertex_array_object(GLctx);

        __webgl_enable_WEBGL_draw_buffers(GLctx);

        GLctx.disjointTimerQueryExt = GLctx.getExtension("EXT_disjoint_timer_query");

        __webgl_enable_WEBGL_multi_draw(GLctx);

        var exts = GLctx.getSupportedExtensions() || [];
        exts.forEach(function (ext) {
          if (ext.indexOf("lose_context") < 0 && ext.indexOf("debug") < 0) {
            GLctx.getExtension(ext);
          }
        });
      },
      populateUniformTable: function (program) {
        var p = GL.programs[program];
        var ptable = GL.programInfos[program] = {
          uniforms: {},
          maxUniformLength: 0,
          maxAttributeLength: -1,
          maxUniformBlockNameLength: -1
        };
        var utable = ptable.uniforms;
        var numUniforms = GLctx.getProgramParameter(p, 35718);

        for (var i = 0; i < numUniforms; ++i) {
          var u = GLctx.getActiveUniform(p, i);
          var name = u.name;
          ptable.maxUniformLength = Math.max(ptable.maxUniformLength, name.length + 1);

          if (name.slice(-1) == "]") {
            name = name.slice(0, name.lastIndexOf("["));
          }

          var loc = GLctx.getUniformLocation(p, name);

          if (loc) {
            var id = GL.getNewId(GL.uniforms);
            utable[name] = [u.size, id];
            GL.uniforms[id] = loc;

            for (var j = 1; j < u.size; ++j) {
              var n = name + "[" + j + "]";
              loc = GLctx.getUniformLocation(p, n);
              id = GL.getNewId(GL.uniforms);
              GL.uniforms[id] = loc;
            }
          }
        }
      }
    };
    var __emscripten_webgl_power_preferences = ["default", "low-power", "high-performance"];

    function _emscripten_webgl_do_create_context(target, attributes) {
      var a = attributes >> 2;
      var powerPreference = GROWABLE_HEAP_I32()[a + (24 >> 2)];
      var contextAttributes = {
        "alpha": !!GROWABLE_HEAP_I32()[a + (0 >> 2)],
        "depth": !!GROWABLE_HEAP_I32()[a + (4 >> 2)],
        "stencil": !!GROWABLE_HEAP_I32()[a + (8 >> 2)],
        "antialias": !!GROWABLE_HEAP_I32()[a + (12 >> 2)],
        "premultipliedAlpha": !!GROWABLE_HEAP_I32()[a + (16 >> 2)],
        "preserveDrawingBuffer": !!GROWABLE_HEAP_I32()[a + (20 >> 2)],
        "powerPreference": __emscripten_webgl_power_preferences[powerPreference],
        "failIfMajorPerformanceCaveat": !!GROWABLE_HEAP_I32()[a + (28 >> 2)],
        majorVersion: GROWABLE_HEAP_I32()[a + (32 >> 2)],
        minorVersion: GROWABLE_HEAP_I32()[a + (36 >> 2)],
        enableExtensionsByDefault: GROWABLE_HEAP_I32()[a + (40 >> 2)],
        explicitSwapControl: GROWABLE_HEAP_I32()[a + (44 >> 2)],
        proxyContextToMainThread: GROWABLE_HEAP_I32()[a + (48 >> 2)],
        renderViaOffscreenBackBuffer: GROWABLE_HEAP_I32()[a + (52 >> 2)]
      };
      var canvas = findCanvasEventTarget(target);

      if (!canvas) {
        return 0;
      }

      if (contextAttributes.explicitSwapControl) {
        return 0;
      }

      var contextHandle = GL.createContext(canvas, contextAttributes);
      return contextHandle;
    }

    function _emscripten_webgl_create_context(a0, a1) {
      return _emscripten_webgl_do_create_context(a0, a1);
    }

    var SYSCALLS = {
      mappings: {},
      buffers: [null, [], []],
      printChar: function (stream, curr) {
        var buffer = SYSCALLS.buffers[stream];

        if (curr === 0 || curr === 10) {
          (stream === 1 ? out : err)(UTF8ArrayToString(buffer, 0));
          buffer.length = 0;
        } else {
          buffer.push(curr);
        }
      },
      varargs: undefined,
      get: function () {
        SYSCALLS.varargs += 4;
        var ret = GROWABLE_HEAP_I32()[SYSCALLS.varargs - 4 >> 2];
        return ret;
      },
      getStr: function (ptr) {
        var ret = UTF8ToString(ptr);
        return ret;
      },
      get64: function (low, high) {
        return low;
      }
    };

    function _fd_close(fd) {
      if (ENVIRONMENT_IS_PTHREAD) return _emscripten_proxy_to_main_thread_js(3, 1, fd);
      return 0;
    }

    function _fd_seek(fd, offset_low, offset_high, whence, newOffset) {
      if (ENVIRONMENT_IS_PTHREAD) return _emscripten_proxy_to_main_thread_js(4, 1, fd, offset_low, offset_high, whence, newOffset);
    }

    function _fd_write(fd, iov, iovcnt, pnum) {
      if (ENVIRONMENT_IS_PTHREAD) return _emscripten_proxy_to_main_thread_js(5, 1, fd, iov, iovcnt, pnum);
      var num = 0;

      for (var i = 0; i < iovcnt; i++) {
        var ptr = GROWABLE_HEAP_I32()[iov + i * 8 >> 2];
        var len = GROWABLE_HEAP_I32()[iov + (i * 8 + 4) >> 2];

        for (var j = 0; j < len; j++) {
          SYSCALLS.printChar(fd, GROWABLE_HEAP_U8()[ptr + j]);
        }

        num += len;
      }

      GROWABLE_HEAP_I32()[pnum >> 2] = num;
      return 0;
    }

    function _pthread_cleanup_pop(execute) {
      var routine = PThread.threadExitHandlers.pop();
      if (execute) routine();
    }

    function _pthread_cleanup_push(routine, arg) {
      PThread.threadExitHandlers.push(function () {
        wasmTable.get(routine)(arg);
      });
    }

    function spawnThread(threadParams) {
      if (ENVIRONMENT_IS_PTHREAD) throw "Internal Error! spawnThread() can only ever be called from main application thread!";
      var worker = PThread.getNewWorker();
      if (worker.pthread !== undefined) throw "Internal error!";
      if (!threadParams.pthread_ptr) throw "Internal error, no pthread ptr!";
      PThread.runningWorkers.push(worker);

      var tlsMemory = _malloc(128 * 4);

      for (var i = 0; i < 128; ++i) {
        GROWABLE_HEAP_I32()[tlsMemory + i * 4 >> 2] = 0;
      }

      var stackHigh = threadParams.stackBase + threadParams.stackSize;
      var pthread = PThread.pthreads[threadParams.pthread_ptr] = {
        worker: worker,
        stackBase: threadParams.stackBase,
        stackSize: threadParams.stackSize,
        allocatedOwnStack: threadParams.allocatedOwnStack,
        threadInfoStruct: threadParams.pthread_ptr
      };
      var tis = pthread.threadInfoStruct >> 2;
      Atomics.store(GROWABLE_HEAP_U32(), tis + (64 >> 2), threadParams.detached);
      Atomics.store(GROWABLE_HEAP_U32(), tis + (100 >> 2), tlsMemory);
      Atomics.store(GROWABLE_HEAP_U32(), tis + (40 >> 2), pthread.threadInfoStruct);
      Atomics.store(GROWABLE_HEAP_U32(), tis + (80 >> 2), threadParams.stackSize);
      Atomics.store(GROWABLE_HEAP_U32(), tis + (76 >> 2), stackHigh);
      Atomics.store(GROWABLE_HEAP_U32(), tis + (104 >> 2), threadParams.stackSize);
      Atomics.store(GROWABLE_HEAP_U32(), tis + (104 + 8 >> 2), stackHigh);
      Atomics.store(GROWABLE_HEAP_U32(), tis + (104 + 12 >> 2), threadParams.detached);

      var global_libc = _emscripten_get_global_libc();

      var global_locale = global_libc + 40;
      Atomics.store(GROWABLE_HEAP_U32(), tis + (172 >> 2), global_locale);
      worker.pthread = pthread;
      var msg = {
        "cmd": "run",
        "start_routine": threadParams.startRoutine,
        "arg": threadParams.arg,
        "threadInfoStruct": threadParams.pthread_ptr,
        "stackBase": threadParams.stackBase,
        "stackSize": threadParams.stackSize
      };

      worker.runPthread = function () {
        msg.time = performance.now();
        worker.postMessage(msg, threadParams.transferList);
      };

      if (worker.loaded) {
        worker.runPthread();
        delete worker.runPthread;
      }
    }

    function _pthread_create(pthread_ptr, attr, start_routine, arg) {
      if (typeof SharedArrayBuffer === "undefined") {
        err("Current environment does not support SharedArrayBuffer, pthreads are not available!");
        return 6;
      }

      if (!pthread_ptr) {
        err("pthread_create called with a null thread pointer!");
        return 28;
      }

      var transferList = [];
      var error = 0;

      if (ENVIRONMENT_IS_PTHREAD && (transferList.length === 0 || error)) {
        return _emscripten_sync_run_in_main_thread_4(687865856, pthread_ptr, attr, start_routine, arg);
      }

      if (error) return error;
      var stackSize = 0;
      var stackBase = 0;
      var detached = 0;

      if (attr && attr != -1) {
        stackSize = GROWABLE_HEAP_I32()[attr >> 2];
        stackSize += 81920;
        stackBase = GROWABLE_HEAP_I32()[attr + 8 >> 2];
        detached = GROWABLE_HEAP_I32()[attr + 12 >> 2] !== 0;
      } else {
        stackSize = 2097152;
      }

      var allocatedOwnStack = stackBase == 0;

      if (allocatedOwnStack) {
        stackBase = _memalign(16, stackSize);
      } else {
        stackBase -= stackSize;
        assert(stackBase > 0);
      }

      var threadInfoStruct = _malloc(228);

      for (var i = 0; i < 228 >> 2; ++i) GROWABLE_HEAP_U32()[(threadInfoStruct >> 2) + i] = 0;

      GROWABLE_HEAP_I32()[pthread_ptr >> 2] = threadInfoStruct;
      GROWABLE_HEAP_I32()[threadInfoStruct + 12 >> 2] = threadInfoStruct;
      var headPtr = threadInfoStruct + 152;
      GROWABLE_HEAP_I32()[headPtr >> 2] = headPtr;
      var threadParams = {
        stackBase: stackBase,
        stackSize: stackSize,
        allocatedOwnStack: allocatedOwnStack,
        detached: detached,
        startRoutine: start_routine,
        pthread_ptr: threadInfoStruct,
        arg: arg,
        transferList: transferList
      };

      if (ENVIRONMENT_IS_PTHREAD) {
        threadParams.cmd = "spawnThread";
        postMessage(threadParams, transferList);
      } else {
        spawnThread(threadParams);
      }

      return 0;
    }

    function __pthread_testcancel_js() {
      if (!ENVIRONMENT_IS_PTHREAD) return;

      var tb = _pthread_self();

      if (!tb) return;
      var cancelDisabled = Atomics.load(GROWABLE_HEAP_U32(), tb + 56 >> 2);
      if (cancelDisabled) return;
      var canceled = Atomics.load(GROWABLE_HEAP_U32(), tb + 0 >> 2);
      if (canceled == 2) throw "Canceled!";
    }

    function _emscripten_check_blocking_allowed() {
      if (ENVIRONMENT_IS_NODE) return;
      if (ENVIRONMENT_IS_WORKER) return;
      warnOnce("Blocking on the main thread is very dangerous, see https://emscripten.org/docs/porting/pthreads.html#blocking-on-the-main-browser-thread");
    }

    function __emscripten_do_pthread_join(thread, status, block) {
      if (!thread) {
        err("pthread_join attempted on a null thread pointer!");
        return ERRNO_CODES.ESRCH;
      }

      if (ENVIRONMENT_IS_PTHREAD && _pthread_self() == thread) {
        err("PThread " + thread + " is attempting to join to itself!");
        return ERRNO_CODES.EDEADLK;
      } else if (!ENVIRONMENT_IS_PTHREAD && _emscripten_main_browser_thread_id() == thread) {
        err("Main thread " + thread + " is attempting to join to itself!");
        return ERRNO_CODES.EDEADLK;
      }

      var self = GROWABLE_HEAP_I32()[thread + 12 >> 2];

      if (self !== thread) {
        err("pthread_join attempted on thread " + thread + ", which does not point to a valid thread, or does not exist anymore!");
        return ERRNO_CODES.ESRCH;
      }

      var detached = Atomics.load(GROWABLE_HEAP_U32(), thread + 64 >> 2);

      if (detached) {
        err("Attempted to join thread " + thread + ", which was already detached!");
        return ERRNO_CODES.EINVAL;
      }

      if (block) {
        _emscripten_check_blocking_allowed();
      }

      for (;;) {
        var threadStatus = Atomics.load(GROWABLE_HEAP_U32(), thread + 0 >> 2);

        if (threadStatus == 1) {
          var threadExitCode = Atomics.load(GROWABLE_HEAP_U32(), thread + 4 >> 2);
          if (status) GROWABLE_HEAP_I32()[status >> 2] = threadExitCode;
          Atomics.store(GROWABLE_HEAP_U32(), thread + 64 >> 2, 1);
          if (!ENVIRONMENT_IS_PTHREAD) cleanupThread(thread);else postMessage({
            "cmd": "cleanupThread",
            "thread": thread
          });
          return 0;
        }

        if (!block) {
          return ERRNO_CODES.EBUSY;
        }

        __pthread_testcancel_js();

        if (!ENVIRONMENT_IS_PTHREAD) _emscripten_main_thread_process_queued_calls();

        _emscripten_futex_wait(thread + 0, threadStatus, ENVIRONMENT_IS_PTHREAD ? 100 : 1);
      }
    }

    function _pthread_join(thread, status) {
      return __emscripten_do_pthread_join(thread, status, true);
    }

    function _sysconf(name) {
      if (ENVIRONMENT_IS_PTHREAD) return _emscripten_proxy_to_main_thread_js(6, 1, name);

      switch (name) {
        case 30:
          return 16384;

        case 85:
          var maxHeapSize = 2147483648;
          return maxHeapSize / 16384;

        case 132:
        case 133:
        case 12:
        case 137:
        case 138:
        case 15:
        case 235:
        case 16:
        case 17:
        case 18:
        case 19:
        case 20:
        case 149:
        case 13:
        case 10:
        case 236:
        case 153:
        case 9:
        case 21:
        case 22:
        case 159:
        case 154:
        case 14:
        case 77:
        case 78:
        case 139:
        case 82:
        case 68:
        case 67:
        case 164:
        case 11:
        case 29:
        case 47:
        case 48:
        case 95:
        case 52:
        case 51:
        case 46:
          return 200809;

        case 27:
        case 246:
        case 127:
        case 128:
        case 23:
        case 24:
        case 160:
        case 161:
        case 181:
        case 182:
        case 242:
        case 183:
        case 184:
        case 243:
        case 244:
        case 245:
        case 165:
        case 178:
        case 179:
        case 49:
        case 50:
        case 168:
        case 169:
        case 175:
        case 170:
        case 171:
        case 172:
        case 97:
        case 76:
        case 32:
        case 173:
        case 35:
        case 80:
        case 81:
        case 79:
          return -1;

        case 176:
        case 177:
        case 7:
        case 155:
        case 8:
        case 157:
        case 125:
        case 126:
        case 92:
        case 93:
        case 129:
        case 130:
        case 131:
        case 94:
        case 91:
          return 1;

        case 74:
        case 60:
        case 69:
        case 70:
        case 4:
          return 1024;

        case 31:
        case 42:
        case 72:
          return 32;

        case 87:
        case 26:
        case 33:
          return 2147483647;

        case 34:
        case 1:
          return 47839;

        case 38:
        case 36:
          return 99;

        case 43:
        case 37:
          return 2048;

        case 0:
          return 2097152;

        case 3:
          return 65536;

        case 28:
          return 32768;

        case 44:
          return 32767;

        case 75:
          return 16384;

        case 39:
          return 1000;

        case 89:
          return 700;

        case 71:
          return 256;

        case 40:
          return 255;

        case 2:
          return 100;

        case 180:
          return 64;

        case 25:
          return 20;

        case 5:
          return 16;

        case 6:
          return 6;

        case 73:
          return 4;

        case 84:
          {
            if (typeof navigator === "object") return navigator["hardwareConcurrency"] || 1;
            return 1;
          }
      }

      setErrNo(28);
      return -1;
    }

    if (!ENVIRONMENT_IS_PTHREAD) PThread.initMainThreadBlock();
    var GLctx;
    var proxiedFunctionTable = [null, _atexit, _emscripten_set_canvas_element_size_main_thread, _fd_close, _fd_seek, _fd_write, _sysconf];
    var asmLibraryArg = {
      "e": ___assert_fail,
      "r": ___call_main,
      "x": __emscripten_notify_thread_queue,
      "b": _abort,
      "y": _emscripten_asm_const_int,
      "j": _emscripten_conditional_set_current_thread_status,
      "d": _emscripten_futex_wait,
      "c": _emscripten_futex_wake,
      "f": _emscripten_get_now,
      "p": _emscripten_memcpy_big,
      "A": _emscripten_num_logical_cores,
      "u": _emscripten_receive_on_main_thread_js,
      "q": _emscripten_resize_heap,
      "v": _emscripten_set_canvas_element_size,
      "i": _emscripten_set_current_thread_status,
      "s": _emscripten_set_thread_name,
      "w": _emscripten_webgl_create_context,
      "l": _fd_close,
      "n": _fd_seek,
      "g": _fd_write,
      "o": initPthreadsJS,
      "a": wasmMemory || Module["wasmMemory"],
      "z": _pthread_cleanup_pop,
      "k": _pthread_cleanup_push,
      "h": _pthread_create,
      "m": _pthread_join,
      "t": _sysconf
    };
    var asm = createWasm();

    var ___wasm_call_ctors = Module["___wasm_call_ctors"] = function () {
      return (___wasm_call_ctors = Module["___wasm_call_ctors"] = Module["asm"]["B"]).apply(null, arguments);
    };

    var _init = Module["_init"] = function () {
      return (_init = Module["_init"] = Module["asm"]["C"]).apply(null, arguments);
    };

    var _init_with_threads_count = Module["_init_with_threads_count"] = function () {
      return (_init_with_threads_count = Module["_init_with_threads_count"] = Module["asm"]["D"]).apply(null, arguments);
    };

    var _get_threads_count = Module["_get_threads_count"] = function () {
      return (_get_threads_count = Module["_get_threads_count"] = Module["asm"]["E"]).apply(null, arguments);
    };

    var _register_tensor = Module["_register_tensor"] = function () {
      return (_register_tensor = Module["_register_tensor"] = Module["asm"]["F"]).apply(null, arguments);
    };

    var _dispose_data = Module["_dispose_data"] = function () {
      return (_dispose_data = Module["_dispose_data"] = Module["asm"]["G"]).apply(null, arguments);
    };

    var _dispose = Module["_dispose"] = function () {
      return (_dispose = Module["_dispose"] = Module["asm"]["H"]).apply(null, arguments);
    };

    var _Abs = Module["_Abs"] = function () {
      return (_Abs = Module["_Abs"] = Module["asm"]["J"]).apply(null, arguments);
    };

    var _Add = Module["_Add"] = function () {
      return (_Add = Module["_Add"] = Module["asm"]["K"]).apply(null, arguments);
    };

    var _AddN = Module["_AddN"] = function () {
      return (_AddN = Module["_AddN"] = Module["asm"]["L"]).apply(null, arguments);
    };

    var _All = Module["_All"] = function () {
      return (_All = Module["_All"] = Module["asm"]["M"]).apply(null, arguments);
    };

    var _Any = Module["_Any"] = function () {
      return (_Any = Module["_Any"] = Module["asm"]["N"]).apply(null, arguments);
    };

    var _ArgMax = Module["_ArgMax"] = function () {
      return (_ArgMax = Module["_ArgMax"] = Module["asm"]["O"]).apply(null, arguments);
    };

    var _AvgPool = Module["_AvgPool"] = function () {
      return (_AvgPool = Module["_AvgPool"] = Module["asm"]["P"]).apply(null, arguments);
    };

    var _BatchMatMul = Module["_BatchMatMul"] = function () {
      return (_BatchMatMul = Module["_BatchMatMul"] = Module["asm"]["Q"]).apply(null, arguments);
    };

    var _Ceil = Module["_Ceil"] = function () {
      return (_Ceil = Module["_Ceil"] = Module["asm"]["R"]).apply(null, arguments);
    };

    var _ClipByValue = Module["_ClipByValue"] = function () {
      return (_ClipByValue = Module["_ClipByValue"] = Module["asm"]["S"]).apply(null, arguments);
    };

    var _Conv2D = Module["_Conv2D"] = function () {
      return (_Conv2D = Module["_Conv2D"] = Module["asm"]["T"]).apply(null, arguments);
    };

    var _Conv2DBackpropInput = Module["_Conv2DBackpropInput"] = function () {
      return (_Conv2DBackpropInput = Module["_Conv2DBackpropInput"] = Module["asm"]["U"]).apply(null, arguments);
    };

    var _Cos = Module["_Cos"] = function () {
      return (_Cos = Module["_Cos"] = Module["asm"]["V"]).apply(null, arguments);
    };

    var _Cosh = Module["_Cosh"] = function () {
      return (_Cosh = Module["_Cosh"] = Module["asm"]["W"]).apply(null, arguments);
    };

    var _CropAndResize = Module["_CropAndResize"] = function () {
      return (_CropAndResize = Module["_CropAndResize"] = Module["asm"]["X"]).apply(null, arguments);
    };

    var _Cumsum = Module["_Cumsum"] = function () {
      return (_Cumsum = Module["_Cumsum"] = Module["asm"]["Y"]).apply(null, arguments);
    };

    var _DepthToSpace = Module["_DepthToSpace"] = function () {
      return (_DepthToSpace = Module["_DepthToSpace"] = Module["asm"]["Z"]).apply(null, arguments);
    };

    var _DepthwiseConv2dNative = Module["_DepthwiseConv2dNative"] = function () {
      return (_DepthwiseConv2dNative = Module["_DepthwiseConv2dNative"] = Module["asm"]["_"]).apply(null, arguments);
    };

    var _Elu = Module["_Elu"] = function () {
      return (_Elu = Module["_Elu"] = Module["asm"]["$"]).apply(null, arguments);
    };

    var _Equal = Module["_Equal"] = function () {
      return (_Equal = Module["_Equal"] = Module["asm"]["aa"]).apply(null, arguments);
    };

    var _Exp = Module["_Exp"] = function () {
      return (_Exp = Module["_Exp"] = Module["asm"]["ba"]).apply(null, arguments);
    };

    var _FlipLeftRight = Module["_FlipLeftRight"] = function () {
      return (_FlipLeftRight = Module["_FlipLeftRight"] = Module["asm"]["ca"]).apply(null, arguments);
    };

    var _Floor = Module["_Floor"] = function () {
      return (_Floor = Module["_Floor"] = Module["asm"]["da"]).apply(null, arguments);
    };

    var _FloorDiv = Module["_FloorDiv"] = function () {
      return (_FloorDiv = Module["_FloorDiv"] = Module["asm"]["ea"]).apply(null, arguments);
    };

    var _FusedBatchNorm = Module["_FusedBatchNorm"] = function () {
      return (_FusedBatchNorm = Module["_FusedBatchNorm"] = Module["asm"]["fa"]).apply(null, arguments);
    };

    var _FusedConv2D = Module["_FusedConv2D"] = function () {
      return (_FusedConv2D = Module["_FusedConv2D"] = Module["asm"]["ga"]).apply(null, arguments);
    };

    var _FusedDepthwiseConv2D = Module["_FusedDepthwiseConv2D"] = function () {
      return (_FusedDepthwiseConv2D = Module["_FusedDepthwiseConv2D"] = Module["asm"]["ha"]).apply(null, arguments);
    };

    var _Gather = Module["_Gather"] = function () {
      return (_Gather = Module["_Gather"] = Module["asm"]["ia"]).apply(null, arguments);
    };

    var _GatherNd = Module["_GatherNd"] = function () {
      return (_GatherNd = Module["_GatherNd"] = Module["asm"]["ja"]).apply(null, arguments);
    };

    var _Greater = Module["_Greater"] = function () {
      return (_Greater = Module["_Greater"] = Module["asm"]["ka"]).apply(null, arguments);
    };

    var _GreaterEqual = Module["_GreaterEqual"] = function () {
      return (_GreaterEqual = Module["_GreaterEqual"] = Module["asm"]["la"]).apply(null, arguments);
    };

    var _LeakyRelu = Module["_LeakyRelu"] = function () {
      return (_LeakyRelu = Module["_LeakyRelu"] = Module["asm"]["ma"]).apply(null, arguments);
    };

    var _Less = Module["_Less"] = function () {
      return (_Less = Module["_Less"] = Module["asm"]["na"]).apply(null, arguments);
    };

    var _LessEqual = Module["_LessEqual"] = function () {
      return (_LessEqual = Module["_LessEqual"] = Module["asm"]["oa"]).apply(null, arguments);
    };

    var _Log = Module["_Log"] = function () {
      return (_Log = Module["_Log"] = Module["asm"]["pa"]).apply(null, arguments);
    };

    var _LogicalAnd = Module["_LogicalAnd"] = function () {
      return (_LogicalAnd = Module["_LogicalAnd"] = Module["asm"]["qa"]).apply(null, arguments);
    };

    var _Max = Module["_Max"] = function () {
      return (_Max = Module["_Max"] = Module["asm"]["ra"]).apply(null, arguments);
    };

    var _MaxPool = Module["_MaxPool"] = function () {
      return (_MaxPool = Module["_MaxPool"] = Module["asm"]["sa"]).apply(null, arguments);
    };

    var _Maximum = Module["_Maximum"] = function () {
      return (_Maximum = Module["_Maximum"] = Module["asm"]["ta"]).apply(null, arguments);
    };

    var _Mean = Module["_Mean"] = function () {
      return (_Mean = Module["_Mean"] = Module["asm"]["ua"]).apply(null, arguments);
    };

    var _Min = Module["_Min"] = function () {
      return (_Min = Module["_Min"] = Module["asm"]["va"]).apply(null, arguments);
    };

    var _Minimum = Module["_Minimum"] = function () {
      return (_Minimum = Module["_Minimum"] = Module["asm"]["wa"]).apply(null, arguments);
    };

    var _MirrorPad = Module["_MirrorPad"] = function () {
      return (_MirrorPad = Module["_MirrorPad"] = Module["asm"]["xa"]).apply(null, arguments);
    };

    var _Multiply = Module["_Multiply"] = function () {
      return (_Multiply = Module["_Multiply"] = Module["asm"]["ya"]).apply(null, arguments);
    };

    var _Neg = Module["_Neg"] = function () {
      return (_Neg = Module["_Neg"] = Module["asm"]["za"]).apply(null, arguments);
    };

    var _NonMaxSuppressionV3 = Module["_NonMaxSuppressionV3"] = function () {
      return (_NonMaxSuppressionV3 = Module["_NonMaxSuppressionV3"] = Module["asm"]["Aa"]).apply(null, arguments);
    };

    var _NonMaxSuppressionV4 = Module["_NonMaxSuppressionV4"] = function () {
      return (_NonMaxSuppressionV4 = Module["_NonMaxSuppressionV4"] = Module["asm"]["Ba"]).apply(null, arguments);
    };

    var _NonMaxSuppressionV5 = Module["_NonMaxSuppressionV5"] = function () {
      return (_NonMaxSuppressionV5 = Module["_NonMaxSuppressionV5"] = Module["asm"]["Ca"]).apply(null, arguments);
    };

    var _NotEqual = Module["_NotEqual"] = function () {
      return (_NotEqual = Module["_NotEqual"] = Module["asm"]["Da"]).apply(null, arguments);
    };

    var _OneHot = Module["_OneHot"] = function () {
      return (_OneHot = Module["_OneHot"] = Module["asm"]["Ea"]).apply(null, arguments);
    };

    var _PadV2 = Module["_PadV2"] = function () {
      return (_PadV2 = Module["_PadV2"] = Module["asm"]["Fa"]).apply(null, arguments);
    };

    var _Pow = Module["_Pow"] = function () {
      return (_Pow = Module["_Pow"] = Module["asm"]["Ga"]).apply(null, arguments);
    };

    var _Prelu = Module["_Prelu"] = function () {
      return (_Prelu = Module["_Prelu"] = Module["asm"]["Ha"]).apply(null, arguments);
    };

    var _Prod = Module["_Prod"] = function () {
      return (_Prod = Module["_Prod"] = Module["asm"]["Ia"]).apply(null, arguments);
    };

    var _RealDiv = Module["_RealDiv"] = function () {
      return (_RealDiv = Module["_RealDiv"] = Module["asm"]["Ja"]).apply(null, arguments);
    };

    var _Relu = Module["_Relu"] = function () {
      return (_Relu = Module["_Relu"] = Module["asm"]["Ka"]).apply(null, arguments);
    };

    var _Relu6 = Module["_Relu6"] = function () {
      return (_Relu6 = Module["_Relu6"] = Module["asm"]["La"]).apply(null, arguments);
    };

    var _ResizeBilinear = Module["_ResizeBilinear"] = function () {
      return (_ResizeBilinear = Module["_ResizeBilinear"] = Module["asm"]["Ma"]).apply(null, arguments);
    };

    var _Reverse = Module["_Reverse"] = function () {
      return (_Reverse = Module["_Reverse"] = Module["asm"]["Na"]).apply(null, arguments);
    };

    var _RotateWithOffset = Module["_RotateWithOffset"] = function () {
      return (_RotateWithOffset = Module["_RotateWithOffset"] = Module["asm"]["Oa"]).apply(null, arguments);
    };

    var _Round = Module["_Round"] = function () {
      return (_Round = Module["_Round"] = Module["asm"]["Pa"]).apply(null, arguments);
    };

    var _Rsqrt = Module["_Rsqrt"] = function () {
      return (_Rsqrt = Module["_Rsqrt"] = Module["asm"]["Qa"]).apply(null, arguments);
    };

    var _ScatterNd = Module["_ScatterNd"] = function () {
      return (_ScatterNd = Module["_ScatterNd"] = Module["asm"]["Ra"]).apply(null, arguments);
    };

    var _SelectV2 = Module["_SelectV2"] = function () {
      return (_SelectV2 = Module["_SelectV2"] = Module["asm"]["Sa"]).apply(null, arguments);
    };

    var _Sigmoid = Module["_Sigmoid"] = function () {
      return (_Sigmoid = Module["_Sigmoid"] = Module["asm"]["Ta"]).apply(null, arguments);
    };

    var _Sin = Module["_Sin"] = function () {
      return (_Sin = Module["_Sin"] = Module["asm"]["Ua"]).apply(null, arguments);
    };

    var _Softmax = Module["_Softmax"] = function () {
      return (_Softmax = Module["_Softmax"] = Module["asm"]["Va"]).apply(null, arguments);
    };

    var _SparseFillEmptyRows = Module["_SparseFillEmptyRows"] = function () {
      return (_SparseFillEmptyRows = Module["_SparseFillEmptyRows"] = Module["asm"]["Wa"]).apply(null, arguments);
    };

    var _SparseReshape = Module["_SparseReshape"] = function () {
      return (_SparseReshape = Module["_SparseReshape"] = Module["asm"]["Xa"]).apply(null, arguments);
    };

    var _SparseSegmentReduction = Module["_SparseSegmentReduction"] = function () {
      return (_SparseSegmentReduction = Module["_SparseSegmentReduction"] = Module["asm"]["Ya"]).apply(null, arguments);
    };

    var _Sqrt = Module["_Sqrt"] = function () {
      return (_Sqrt = Module["_Sqrt"] = Module["asm"]["Za"]).apply(null, arguments);
    };

    var _Square = Module["_Square"] = function () {
      return (_Square = Module["_Square"] = Module["asm"]["_a"]).apply(null, arguments);
    };

    var _SquaredDifference = Module["_SquaredDifference"] = function () {
      return (_SquaredDifference = Module["_SquaredDifference"] = Module["asm"]["$a"]).apply(null, arguments);
    };

    var _Step = Module["_Step"] = function () {
      return (_Step = Module["_Step"] = Module["asm"]["ab"]).apply(null, arguments);
    };

    var _StridedSlice = Module["_StridedSlice"] = function () {
      return (_StridedSlice = Module["_StridedSlice"] = Module["asm"]["bb"]).apply(null, arguments);
    };

    var _Sub = Module["_Sub"] = function () {
      return (_Sub = Module["_Sub"] = Module["asm"]["cb"]).apply(null, arguments);
    };

    var _Sum = Module["_Sum"] = function () {
      return (_Sum = Module["_Sum"] = Module["asm"]["db"]).apply(null, arguments);
    };

    var _Tan = Module["_Tan"] = function () {
      return (_Tan = Module["_Tan"] = Module["asm"]["eb"]).apply(null, arguments);
    };

    var _Tanh = Module["_Tanh"] = function () {
      return (_Tanh = Module["_Tanh"] = Module["asm"]["fb"]).apply(null, arguments);
    };

    var _Tile = Module["_Tile"] = function () {
      return (_Tile = Module["_Tile"] = Module["asm"]["gb"]).apply(null, arguments);
    };

    var _TopK = Module["_TopK"] = function () {
      return (_TopK = Module["_TopK"] = Module["asm"]["hb"]).apply(null, arguments);
    };

    var _Transform = Module["_Transform"] = function () {
      return (_Transform = Module["_Transform"] = Module["asm"]["ib"]).apply(null, arguments);
    };

    var _Transpose = Module["_Transpose"] = function () {
      return (_Transpose = Module["_Transpose"] = Module["asm"]["jb"]).apply(null, arguments);
    };

    var __FusedMatMul = Module["__FusedMatMul"] = function () {
      return (__FusedMatMul = Module["__FusedMatMul"] = Module["asm"]["kb"]).apply(null, arguments);
    };

    var _malloc = Module["_malloc"] = function () {
      return (_malloc = Module["_malloc"] = Module["asm"]["lb"]).apply(null, arguments);
    };

    var _free = Module["_free"] = function () {
      return (_free = Module["_free"] = Module["asm"]["mb"]).apply(null, arguments);
    };

    var ___errno_location = Module["___errno_location"] = function () {
      return (___errno_location = Module["___errno_location"] = Module["asm"]["nb"]).apply(null, arguments);
    };

    var _emscripten_get_global_libc = Module["_emscripten_get_global_libc"] = function () {
      return (_emscripten_get_global_libc = Module["_emscripten_get_global_libc"] = Module["asm"]["ob"]).apply(null, arguments);
    };

    var _pthread_self = Module["_pthread_self"] = function () {
      return (_pthread_self = Module["_pthread_self"] = Module["asm"]["pb"]).apply(null, arguments);
    };

    var ___pthread_tsd_run_dtors = Module["___pthread_tsd_run_dtors"] = function () {
      return (___pthread_tsd_run_dtors = Module["___pthread_tsd_run_dtors"] = Module["asm"]["qb"]).apply(null, arguments);
    };

    var _emscripten_main_thread_process_queued_calls = Module["_emscripten_main_thread_process_queued_calls"] = function () {
      return (_emscripten_main_thread_process_queued_calls = Module["_emscripten_main_thread_process_queued_calls"] = Module["asm"]["rb"]).apply(null, arguments);
    };

    var _emscripten_current_thread_process_queued_calls = Module["_emscripten_current_thread_process_queued_calls"] = function () {
      return (_emscripten_current_thread_process_queued_calls = Module["_emscripten_current_thread_process_queued_calls"] = Module["asm"]["sb"]).apply(null, arguments);
    };

    var _emscripten_register_main_browser_thread_id = Module["_emscripten_register_main_browser_thread_id"] = function () {
      return (_emscripten_register_main_browser_thread_id = Module["_emscripten_register_main_browser_thread_id"] = Module["asm"]["tb"]).apply(null, arguments);
    };

    var _emscripten_main_browser_thread_id = Module["_emscripten_main_browser_thread_id"] = function () {
      return (_emscripten_main_browser_thread_id = Module["_emscripten_main_browser_thread_id"] = Module["asm"]["ub"]).apply(null, arguments);
    };

    var __emscripten_do_dispatch_to_thread = Module["__emscripten_do_dispatch_to_thread"] = function () {
      return (__emscripten_do_dispatch_to_thread = Module["__emscripten_do_dispatch_to_thread"] = Module["asm"]["vb"]).apply(null, arguments);
    };

    var _emscripten_sync_run_in_main_thread_4 = Module["_emscripten_sync_run_in_main_thread_4"] = function () {
      return (_emscripten_sync_run_in_main_thread_4 = Module["_emscripten_sync_run_in_main_thread_4"] = Module["asm"]["wb"]).apply(null, arguments);
    };

    var _emscripten_run_in_main_runtime_thread_js = Module["_emscripten_run_in_main_runtime_thread_js"] = function () {
      return (_emscripten_run_in_main_runtime_thread_js = Module["_emscripten_run_in_main_runtime_thread_js"] = Module["asm"]["xb"]).apply(null, arguments);
    };

    var __emscripten_call_on_thread = Module["__emscripten_call_on_thread"] = function () {
      return (__emscripten_call_on_thread = Module["__emscripten_call_on_thread"] = Module["asm"]["yb"]).apply(null, arguments);
    };

    var _emscripten_tls_init = Module["_emscripten_tls_init"] = function () {
      return (_emscripten_tls_init = Module["_emscripten_tls_init"] = Module["asm"]["zb"]).apply(null, arguments);
    };

    var __emscripten_thread_init = Module["__emscripten_thread_init"] = function () {
      return (__emscripten_thread_init = Module["__emscripten_thread_init"] = Module["asm"]["Ab"]).apply(null, arguments);
    };

    var stackSave = Module["stackSave"] = function () {
      return (stackSave = Module["stackSave"] = Module["asm"]["Bb"]).apply(null, arguments);
    };

    var stackRestore = Module["stackRestore"] = function () {
      return (stackRestore = Module["stackRestore"] = Module["asm"]["Cb"]).apply(null, arguments);
    };

    var stackAlloc = Module["stackAlloc"] = function () {
      return (stackAlloc = Module["stackAlloc"] = Module["asm"]["Db"]).apply(null, arguments);
    };

    var _emscripten_stack_set_limits = Module["_emscripten_stack_set_limits"] = function () {
      return (_emscripten_stack_set_limits = Module["_emscripten_stack_set_limits"] = Module["asm"]["Eb"]).apply(null, arguments);
    };

    var _memalign = Module["_memalign"] = function () {
      return (_memalign = Module["_memalign"] = Module["asm"]["Fb"]).apply(null, arguments);
    };

    var __emscripten_allow_main_runtime_queued_calls = Module["__emscripten_allow_main_runtime_queued_calls"] = 10656;

    var __emscripten_main_thread_futex = Module["__emscripten_main_thread_futex"] = 12292;

    Module["cwrap"] = cwrap;
    Module["PThread"] = PThread;
    Module["PThread"] = PThread;
    Module["wasmMemory"] = wasmMemory;
    Module["ExitStatus"] = ExitStatus;
    var calledRun;

    function ExitStatus(status) {
      (this || _global).name = "ExitStatus";
      (this || _global).message = "Program terminated with exit(" + status + ")";
      (this || _global).status = status;
    }

    dependenciesFulfilled = function runCaller() {
      if (!calledRun) run();
      if (!calledRun) dependenciesFulfilled = runCaller;
    };

    function run(args) {
      args = args || arguments_;

      if (runDependencies > 0) {
        return;
      }

      if (ENVIRONMENT_IS_PTHREAD) {
        readyPromiseResolve(Module);
        initRuntime();
        postMessage({
          "cmd": "loaded"
        });
        return;
      }

      preRun();

      if (runDependencies > 0) {
        return;
      }

      function doRun() {
        if (calledRun) return;
        calledRun = true;
        Module["calledRun"] = true;
        if (ABORT) return;
        initRuntime();
        preMain();
        readyPromiseResolve(Module);
        if (Module["onRuntimeInitialized"]) Module["onRuntimeInitialized"]();
        postRun();
      }

      if (Module["setStatus"]) {
        Module["setStatus"]("Running...");
        setTimeout(function () {
          setTimeout(function () {
            Module["setStatus"]("");
          }, 1);
          doRun();
        }, 1);
      } else {
        doRun();
      }
    }

    Module["run"] = run;

    function exit(status, implicit) {
      if (implicit && noExitRuntime && status === 0) {
        return;
      }

      if (!implicit) {
        if (ENVIRONMENT_IS_PTHREAD) {
          postMessage({
            "cmd": "exitProcess",
            "returnCode": status
          });
          throw new ExitStatus(status);
        } else {}
      }

      if (noExitRuntime) {} else {
        PThread.terminateAllThreads();
        EXITSTATUS = status;
        exitRuntime();
        if (Module["onExit"]) Module["onExit"](status);
        ABORT = true;
      }

      quit_(status, new ExitStatus(status));
    }

    if (Module["preInit"]) {
      if (typeof Module["preInit"] == "function") Module["preInit"] = [Module["preInit"]];

      while (Module["preInit"].length > 0) {
        Module["preInit"].pop()();
      }
    }

    if (ENVIRONMENT_IS_PTHREAD) {
      noExitRuntime = false;
      PThread.initWorker();
    }

    run();
    var listenersAdded;

    if (beforeListeners) {
      listenersAdded = {
        uncaughtException: process.listeners("uncaughtException").filter(function (listener) {
          return !beforeListeners.uncaughtException.indexOf(listener) > -1;
        }),
        unhandledRejection: process.listeners("unhandledRejection").filter(function (listener) {
          return !beforeListeners.unhandledRejection.indexOf(listener) > -1;
        })
      };
    }

    var actualModule;

    if (typeof WasmBackendModule !== "undefined") {
      actualModule = WasmBackendModule;
    } else if (typeof WasmBackendModuleThreadedSimd !== "undefined") {
      actualModule = WasmBackendModuleThreadedSimd;
    } else {
      throw new Error("Could not find wasm module in post.js");
    }

    if (listenersAdded) {
      var tmpDispose = actualModule["_dispose"];

      actualModule["_dispose"] = function () {
        tmpDispose();
        listenersAdded.uncaughtException.forEach(function (listener) {
          process.removeListener("uncaughtException", listener);
        });
        listenersAdded.unhandledRejection.forEach(function (listener) {
          process.removeListener("unhandledRejection", listener);
        });
      };
    }

    return WasmBackendModuleThreadedSimd.ready;
  };
}();

exports = WasmBackendModuleThreadedSimd;
export default exports;